import React from "react";

const ProjectsReducer = (state, action) => {
  switch (action.type) {
    case "STORE":
      return action.filteredProj;
    default:
      return state;
  }
};

export default ProjectsReducer;
