import React, { useContext } from "react";
import "./projectheader.css";
import { GlobalContext } from "../../../GlobalContext/GlobalProvider";

const ProjHeader = () => {
  const { langState } = useContext(GlobalContext);
  const lang = langState.lang;
  return (
    <div className="project-header-container">
      <div
        className={
          lang === "EN"
            ? "proj-headings"
            : "proj-headings ar-projects arabic-font"
        }
      >
        <h3 className={lang === "AR" && "arabic-font "}>
          {lang === "EN" ? "Arcis" : "اركيس"}
        </h3>
        <h1 className={lang === "AR" && "arabic-font"}>
          {lang === "EN" ? "Projects" : "عرض المشاريع"}
        </h1>
      </div>
      <div className="scroll-bar">
        <div className="scroll"></div>
        <a href="#proj-form-container">Scroll Down</a>
      </div>
    </div>
  );
};

export default ProjHeader;
