import React, { useContext } from "react";
import "./rvwsHeader.css";
import ReviewsSVG from "./images/reviewsSVG.png";
import { GlobalContext } from "../../../GlobalContext/GlobalProvider";
const RvwsHeader = () => {
  const { langState } = useContext(GlobalContext);
  const lang = langState.lang;
  const classHandler = (letter) => {
    return `test-animate test-letter-${letter}`;
  };
  return (
    <div className="testimonials-container">
      <div
        className={
          lang === "EN"
            ? "testimonials-header"
            : "testimonials-header arabic-font"
        }
      >
        {lang === "EN" ? (
          <h1 className="reviews-headline">
            <div className={classHandler("t")}>T</div>
            <div className={classHandler("e")}>e</div>
            <div className={classHandler("s")}>s</div>
            <div className={classHandler("t-two")}>t</div>
            <div className={classHandler("i")}>i</div>
            <div className={classHandler("m")}>m</div>
            <div className={classHandler("o")}>o</div>
            <div className={classHandler("n")}>n</div>
            <div className={classHandler("i-two")}>i</div>
            <div className={classHandler("a")}>a</div>
            <div className={classHandler("l")}>l</div>
            <div className={classHandler("s-two")}>s</div>
          </h1>
        ) : (
          <h1>التعليقات</h1>
        )}
        <div className="img-container">
          <img src={ReviewsSVG} width="350px" alt="" />
        </div>
      </div>
    </div>
  );
};

export default RvwsHeader;
