import React, { useContext, useState } from "react";
import { NavLink } from "react-router-dom";
import LogoColors from "./Image/LogoColors.png";

import "./Navbar.css";
import { GlobalContext } from "../GlobalContext/GlobalProvider";
const Navbar = (props) => {
  const [menuState, setMenuState] = useState(false);
  const { langState } = useContext(GlobalContext);

  const lang = langState.lang;

  function menuHandler() {
    setMenuState(!menuState);
  }
  function handleActive({ isActive }) {
    return isActive ? "under-line-link navbar-links" : "navbar-links";
  }
  function handleProj({ isActive }) {
    return isActive ? "reviews Link background-active " : "reviews Link";
  }

  return (
    <nav>
      <div className="nav-container responsive-nav hidden">
        <div className="image-container">
          <img src={LogoColors} className="image" alt="Logo" />
        </div>
        <div
          className={
            menuState ? "floating-navbar open" : "floating-navbar close"
          }
        >
          <ul
            className={
              lang === "EN" ? "nav-bar-full" : "nav-bar-full arabic-font"
            }
          >
            <li className={lang === "EN" && "arabic-font"}>
              <NavLink
                className={lang === "EN" ? "navLink" : "navLink arabic-font"}
                to={"/"}
              >
                {lang === "EN" ? "Home" : "الرئيسية"}
              </NavLink>
            </li>
            <li className={lang === "EN" && "arabic-font"}>
              <NavLink
                className={lang === "EN" ? "navLink" : "navLink arabic-font"}
                to={"/About"}
              >
                {lang === "EN" ? "About us" : "من نحن"}
              </NavLink>
            </li>
            <li className={lang === "EN" && "arabic-font"}>
              <NavLink
                className={lang === "EN" ? "navLink" : "navLink arabic-font"}
                to={"/Blog"}
              >
                {lang === "EN" ? "Blog" : "الاخبار"}
              </NavLink>
            </li>
            <li className={lang === "EN" && "arabic-font"}>
              <NavLink
                className={lang === "EN" ? "navLink" : "navLink arabic-font"}
                to={"/Reviews"}
              >
                {lang === "EN" ? "Reviews" : "التعليقات"}
              </NavLink>
            </li>
          </ul>
          <div>
            <NavLink to={"/Projects"} className="responsive-rev">
              <button
                className={
                  lang === "EN"
                    ? "btn-style res-btn"
                    : "btn-style res-btn arabic-font"
                }
              >
                {lang === "EN" ? "Projects" : "المشاريع"}
              </button>
            </NavLink>
          </div>
        </div>
        <div className="menu" onClick={menuHandler}>
          <i className="fa-solid fa-bars"></i>
        </div>
      </div>
      <div className="nav-container main-nav">
        <div>
          <img src={LogoColors} className="image" alt="" />
        </div>
        <div>
          <ul
            className={
              lang === "AR" ? "List-parent arabic-font" : "List-parent"
            }
          >
            <NavLink to={"/"} className={handleActive}>
              {lang === "EN" ? "Home" : "الرئيسية"}
            </NavLink>
            <NavLink to={"/About"} className={handleActive}>
              {lang === "EN" ? "About us" : "من نحن"}
            </NavLink>
            <NavLink to={"/Blog"} className={handleActive}>
              {lang === "EN" ? "Blog" : "الاخبار"}
            </NavLink>
            <NavLink to={"/Reviews"} className={handleActive}>
              {lang === "EN" ? "Reviews" : "التعليقات"}
            </NavLink>
          </ul>
        </div>
        <div id="lnk " className={lang === "AR" ? "arabic-font" : ""}>
          <NavLink to={"/Projects"} className={handleProj}>
            {lang === "EN" ? "Projects" : "المشاريع"}
          </NavLink>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
