import React, { useContext, useState } from "react";
import "./VideoSect.css";
import { NavLink } from "react-router-dom";
import Promo from "./video/semiPromo.mp4";
import { GlobalContext } from "../../GlobalContext/GlobalProvider";
import Spinner from "../../spinner/Spinner";

const VideoSect = () => {
  const { lookupsData, loading, langState } = useContext(GlobalContext);
  const { color, setColor } = useState("#000");
  const lang = langState.lang;
  console.log(lang);
  console.log(loading);
  // const promo = lookupsData.AboutUs.video;
  // console.log(lookupsData.AboutUs.video);

  return (
    <div className="video-container">
      <div className="video contain">
        {loading ? (
          <video
            src={loading ? lookupsData.AboutUs.video : ""}
            className="video__source"
            muted
            loop
            autoPlay
          ></video>
        ) : (
          <Spinner />
        )}
      </div>
      <div
        className={
          lang === "EN"
            ? "about-us contain"
            : "about-us contain arabic-font about-arabic"
        }
      >
        <div className="about">
          <h2>{lang === "EN" ? "About us" : "من نحن"}</h2>
          <p>
            {lang === "EN" &&
              (loading ? lookupsData.AboutUs.firstArticle_en : <Spinner />)}
            {lang === "AR" &&
              (loading ? lookupsData.AboutUs.firstArticle_ar : <Spinner />)}
          </p>
        </div>
        <div className="button-container">
          <div>
            {lang === "EN" ? "Get to know us better" : "تعرف علينا اكثر"}
          </div>
          <NavLink to={"/About"}>
            <button className="btn-style">
              {lang === "EN" ? "Here" : "هنا"}
            </button>
          </NavLink>
        </div>
      </div>
    </div>
  );
};

export default VideoSect;
