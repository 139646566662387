import React, { useContext, useState } from "react";
import ReviewsForm from "./ReviewsForm";
import "./Reviewsform.css";
import { GlobalContext } from "../../../GlobalContext/GlobalProvider";
const CommentSection = () => {
  const [formState, setFormState] = useState(false);
  const { langState } = useContext(GlobalContext);
  function formHandler() {
    setFormState(!formState);
  }

  function classHandler() {
    return formState ? "form-headline form-headline-up" : "form-headline";
  }
  const lang = langState.lang;

  return (
    <div className="form-wrapper">
      <div
        className={
          lang === "AR" ? classHandler() + " arabic-font" : classHandler()
        }
      >
        <h2 className={lang === "AR" && "arabic-font"}>
          {lang === "EN" ? "Comment Section" : "قسم التعليقات"}
        </h2>
        <button onClick={formHandler} className="open-form">
          {lang === "EN"
            ? formState
              ? "Close"
              : " Add a comment"
            : formState
            ? "اغلق"
            : " اترك تعليقا"}
        </button>
      </div>

      <ReviewsForm activeState={formState} />
    </div>
  );
};

export default CommentSection;
