import React from "react";
import "./employ.css";
const EmployeesData = (props) => {
  const cityName = (
    <div
      className={
        props.lang === "EN"
          ? "cityname-container"
          : "cityname-container arabic-font"
      }
    >
      {props.lang === "EN" ? props.cityNameEN : props.cityNameAR}
    </div>
  );
  return (
    <div className="employees-data-container">
      <img src={props.image} width="100%" height="100%" alt="" />
      <div
        className="overlay employees-lay
      "
      ></div>
      {cityName}
      <div
        className={
          props.lang === "EN" ? "employees-data" : "employees-data arabic-font"
        }
      >
        <h4 className={props.lang === "EN" ? "" : "arabic-font"}>
          {props.lang === "EN" ? props.nameEn : props.nameAr}
        </h4>
        <h3>{props.title}</h3>
      </div>
    </div>
  );
};

export default EmployeesData;
