import React, { useContext } from "react";
import "./community.css";
import { NavLink } from "react-router-dom";
import { GlobalContext } from "../../GlobalContext/GlobalProvider";

const Community = () => {
  const { langState } = useContext(GlobalContext);
  const lang = langState.lang;
  return (
    <div className="community-container">
      <div
        className={
          lang === "EN"
            ? "reviews-header"
            : "reviews-header arabic-font ar-community-panel"
        }
      >
        <h1>
          {lang === "EN"
            ? "Join our Reviews community"
            : "انضم الي مجتمع التعليقات الخاص بنا"}
        </h1>
        <h3>
          {lang === "EN"
            ? "by simply adding a comment"
            : " من خلال ترك تعليقا بناء"}
        </h3>
      </div>
      <div className="reviews-btn">
        <NavLink className="rev-link">
          <button
            className={lang === "EN" ? "underline" : "underline arabic-font"}
          >
            {lang === "EN" ? "Leave a comment" : "اترك تعليق"}
          </button>
        </NavLink>
      </div>
    </div>
  );
};

export default Community;
