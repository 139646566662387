import React, { useContext, useEffect, useState, useCallback } from "react";
import mainImage from "./image/Rectangle 54.png";
import { NavLink } from "react-router-dom";
import Filter from "./FilterComponent/Filter";
import "./FirstSect.css";
import { GlobalContext } from "../../GlobalContext/GlobalProvider";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from "swiper";

// Import Swiper styles
import "swiper/css";

import Spinner from "../../spinner/Spinner";

const FirstSection = () => {
  const { langState, loading, lookupsData, idDispatcher } =
    useContext(GlobalContext);
  const [appearance, setAppearance] = useState(false);
  const [search, setSearch] = useState(false);
  const [filteredProjs, setFilteredProjs] = useState([]);
  const [activePanel, setActivePanel] = useState(false);
  const [swiper, setSwiper] = useState(null);
  const [filterLoader, setLoader] = useState(false);
  const lang = langState.lang;

  const aboutData = lookupsData.AboutUs;
  const landingSlider = lookupsData.LandingPage;
  console.log(filterLoader);
  useEffect(() => {
    const interval = setInterval(() => {
      if (swiper) {
        if (swiper.activeIndex < swiper.slides.length - 1) {
          swiper.slideNext();
        } else {
          swiper.slideTo(0);
        }
      }
    }, 10000);

    return () => clearInterval(interval);
  }, [swiper]);
  const handleSwiper = (swiper) => {
    setSwiper(swiper);
  };

  function closeSearchResults() {
    setActivePanel(false);
  }

  const [swiperState, setSwiperState] = useState([{ id: 0, state: true }]);
  function pageHandler(id) {
    idDispatcher({
      type: "CLICKED",
      id,
    });
  }
  function searchHandler() {
    setSearch(!search);
  }

  return (
    <div className="first-container">
      <button
        className={
          lang === "EN" ? "search-btn-panel" : "search-btn-panel arabic-font"
        }
        onClick={searchHandler}
      >
        {lang === "EN"
          ? !search
            ? "Search panel"
            : "Close Panel"
          : !search
          ? "محرك البحث"
          : "اغلق المحرك"}
      </button>

      <div
        className={
          lang === "EN"
            ? search
              ? "first-wrapper-index panel-open"
              : "first-wrapper-index"
            : search
            ? "first-wrapper-index panel-open ar-wrapper-index"
            : "first-wrapper-index ar-wrapper-index"
        }
      >
        <div
          className={
            activePanel
              ? "search-results-container active-results "
              : "search-results-container"
          }
        >
          <div className="search-headline">
            <h1>Search Results</h1>
            <i
              onClick={closeSearchResults}
              style={{ cursor: "pointer" }}
              className="fa-solid fa-xmark x-close"
            ></i>
          </div>
          {filterLoader ? (
            filteredProjs.length !== 0 ? (
              <div className="searched-projects">
                <Swiper spaceBetween={1} slidesPerView={1.1}>
                  {filteredProjs.map(
                    ({ id, title_en, title_ar, mainImage }) => {
                      return (
                        <SwiperSlide>
                          <div className="main-image-container">
                            <div className="filtered-overlay"></div>
                            <div className="proj-link-container">
                              <h2>{lang === "EN" ? title_en : title_ar}</h2>
                              <NavLink to={"/ProjectsData"}>
                                <button
                                  onClick={() => pageHandler(id)}
                                  className="visit-proj"
                                >
                                  {lang === "EN" ? "visit project" : "المشروع"}
                                </button>
                              </NavLink>
                            </div>
                            <img src={mainImage} alt="" />
                          </div>
                        </SwiperSlide>
                      );
                    }
                  )}
                </Swiper>
              </div>
            ) : (
              <h2 className="no-results">No Results To Show</h2>
            )
          ) : (
            <Spinner />
          )}
        </div>
        <div className="description-proj">
          <div className="contained description">
            <div className={lang === "EN" ? "" : "arabic-font"}>
              {lang === "EN" ? (
                loading ? (
                  <h3>{aboutData.firstTitle_en}</h3>
                ) : (
                  <Spinner />
                )
              ) : loading ? (
                <h3>{aboutData.firstTitle_ar}</h3>
              ) : (
                <Spinner />
              )}
              {lang === "EN" ? (
                loading ? (
                  <h1>{aboutData.secondTitle_en}</h1>
                ) : (
                  <Spinner />
                )
              ) : loading ? (
                <h1>{aboutData.secondTitle_ar}</h1>
              ) : (
                <Spinner />
              )}
            </div>
            <div className={lang === "EN" ? "" : "arabic-font ar-para"}>
              {lang === "EN" ? (
                loading ? (
                  <p>{aboutData.description_en}</p>
                ) : (
                  <Spinner />
                )
              ) : loading ? (
                <p>{aboutData.description_ar}</p>
              ) : (
                <Spinner />
              )}
            </div>
          </div>
          <div className="contained btn-contained">
            <NavLink to={"/Projects"} className="project-btn">
              <button
                className={
                  lang === "EN"
                    ? "btn-style projects"
                    : "btn-style projects arabic-font"
                }
              >
                {lang === "EN" ? "Projects" : "المشاريع"}
              </button>
            </NavLink>
          </div>
        </div>
        <div className={lang === "EN" ? "filter" : "filter"}>
          <Filter
            lang={lang}
            filtered={setFilteredProjs}
            active={setActivePanel}
            loader={setLoader}
          />
        </div>
      </div>
      <div className="slideshow-wrapper">
        <Swiper
          className="swiper"
          modules={[Pagination]}
          spaceBetween={-1}
          slidesPerView={1}
          pagination={{ clickable: true }}
          onSwiper={handleSwiper}
          onSlideChange={(idx) => {
            setSwiperState((prev) => [
              {
                id: idx.activeIndex,
                state: true,
              },
            ]);
          }}
        >
          {loading ? (
            landingSlider.map(
              (
                {
                  id,
                  label_en,
                  label_ar,
                  description_en,
                  description_ar,
                  details_en,
                  details_ar,
                  image,
                },
                idx
              ) => {
                return (
                  <SwiperSlide>
                    <div
                      key={id}
                      className="slider-container"
                      style={{ backgroundImage: `url(${image})` }}
                    >
                      <div
                        className={
                          lang === "EN"
                            ? "news-wrapper"
                            : "news-wrapper arabic-font arabic-news-wrapper"
                        }
                      >
                        <div className="news-headlines">
                          <h1 className={lang === "EN" ? "" : "arabic-font"}>
                            {lang === "EN"
                              ? `Latest News ${label_en}`
                              : `اخر الاخبار ${label_ar}`}
                          </h1>
                          <h3>
                            {lang === "EN" ? description_en : description_ar}
                          </h3>
                        </div>
                        <div
                          className={
                            idx == swiperState[0].id && swiperState[0].state
                              ? "property-details-wrapper details-open"
                              : "property-details-wrapper"
                          }
                        >
                          <div className="news-property-details">
                            <p
                              className={
                                lang === "AR" && "arabic-font property-ar-det"
                              }
                            >
                              {lang === "EN" ? details_en : details_ar}
                            </p>
                            <NavLink to={"/Projects"}>
                              <button className="news-property-link">
                                {lang === "EN" ? "Visit Projects" : "المشاريع"}
                              </button>
                            </NavLink>
                          </div>
                        </div>
                      </div>
                    </div>
                  </SwiperSlide>
                );
              }
            )
          ) : (
            <Spinner />
          )}
        </Swiper>
      </div>
    </div>
  );
};

export default FirstSection;
