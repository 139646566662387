import React, { useState, useEffect, useContext } from "react";
import "./Reviewsform.css";
import axios from "axios";
import Timer from "../../../Timer/Timer";
import { GlobalContext } from "../../../GlobalContext/GlobalProvider";

const ReviewsForm = (props) => {
  const { langState } = useContext(GlobalContext);
  const evalTimeout = 600000; // 10 minutes in milliseconds
  const [name, setName] = useState("");
  const [country, setCountry] = useState("");
  const [message, setComment] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [isCooldown, setIsCooldown] = useState(false);
  const [remainingTime, setRemainingTime] = useState(0);

  const lang = langState.lang;
  useEffect(() => {
    // Check if there is a timestamp in localStorage
    const lastSubmissionTime = localStorage.getItem("lastSubmissionTime");
    if (lastSubmissionTime) {
      const timeElapsed = Date.now() - parseInt(lastSubmissionTime);
      if (timeElapsed < evalTimeout) {
        setRemainingTime(evalTimeout - timeElapsed);
        setIsCooldown(true);
      }
    }
  }, []);

  function startCooldownTimer() {
    setIsCooldown(true);
    setRemainingTime(evalTimeout);

    // Store the timestamp of the submission in localStorage
    const currentTimestamp = Date.now().toString();
    localStorage.setItem("lastSubmissionTime", currentTimestamp);

    // Start the countdown timer
    const interval = setInterval(() => {
      setRemainingTime((prevTime) => prevTime - 1000);
    }, 1000);

    // Clear the interval and reset cooldown when the countdown is over
    setTimeout(() => {
      clearInterval(interval);
      setIsCooldown(false);
      localStorage.removeItem("lastSubmissionTime");
    }, evalTimeout);
  }

  function submitHandler(e) {
    e.preventDefault();
    if (name && country && message && email && !isCooldown) {
      axios
        .post("https://admin.arcis.ge/api/GeneralReview/store", {
          name,
          country,
          message,
          email,
          phone,
        })
        .then(() => {
          setCountry("");
          setComment("");
          setEmail("");
          setName("");
          setPhone("");
          startCooldownTimer();
        })
        .catch((error) => {
          console.error("Error submitting review:", error);
        });
    } else if (isCooldown) {
      alert(
        "You can leave one review every 10 minutes. Please wait until the cooldown period is over."
      );
    } else {
      alert("Please fill in all the fields.");
    }
  }

  function nameHandler(e) {
    setName(e.target.value);
  }

  function countryHandler(e) {
    setCountry(e.target.value);
  }

  function commentHandler(e) {
    setComment(e.target.value);
  }

  function phoneHandler(e) {
    setPhone(e.target.value);
  }

  function emailHandler(e) {
    setEmail(e.target.value);
  }

  function formClassHandler() {
    return props.activeState
      ? "form-grid-display form-height-auto"
      : "form-grid-display";
  }

  return (
    <div className={formClassHandler()}>
      <form
        onSubmit={submitHandler}
        className="reviews-form-container"
        action="url"
        method="post"
      >
        <div className="input-containers">
          <input
            className="comment-input"
            required
            onChange={nameHandler}
            type="text"
            id="name"
            name="name"
            value={name}
          />
          <label className="labels form-words" htmlFor="name">
            Name
          </label>
        </div>
        <div className="input-containers">
          <input
            value={country}
            required
            onChange={countryHandler}
            className="comment-input"
            type="text"
            name="country"
            id="country"
          />
          <label className="labels form-words" htmlFor="country">
            Country
          </label>
        </div>
        <div className="input-containers comment">
          <textarea
            required
            onChange={commentHandler}
            className="comment-input"
            type="text"
            name="comment"
            id="comment"
            value={message}
          />
          <label className="labels form-words" htmlFor="comment">
            Add Your Review
          </label>
        </div>
        <div className="input-containers">
          <input
            required
            type="text"
            onChange={emailHandler}
            className="location-input"
            placeholder="Email"
            value={email}
          />
        </div>
        <div className="input-containers">
          <input
            required
            onChange={phoneHandler}
            type="number"
            className="location-input"
            placeholder="Phone"
            value={phone}
          />
        </div>

        <div className="submit-comment">
          {isCooldown ? (
            <div className="eval-container">
              <h3>You can leave one comment every 10 minutes</h3>
            </div>
          ) : (
            <input
              required
              type="submit"
              value="Submit"
              className="btn-style"
            />
          )}
          <h2
            className={isCooldown ? "eval-message eval-active" : "eval-message"}
          >
            Your comment is being evaluated
          </h2>
        </div>
      </form>
    </div>
  );
};

export default ReviewsForm;
