import React, { useContext } from "react";
import "./BlogHeader.css";
import { GlobalContext } from "../../GlobalContext/GlobalProvider";
import Spinner from "../../spinner/Spinner";

const BlogHeader = () => {
  const { lookupsData, langState, loading } = useContext(GlobalContext);
  const lang = langState.lang;
  console.log(lookupsData.Blog);
  const blogData = lookupsData.Blog;
  return (
    <div
      className={"blog-header-container"}
      style={{ backgroundImage: `url(${loading && blogData.image})` }}
    >
      <div className={!loading && "mid"}>
        <h2 className={lang === "AR" && "arabic-font arabic-header-0"}>
          {loading ? (
            lang === "EN" ? (
              blogData.firstTitle_en
            ) : (
              blogData.firstTitle_ar
            )
          ) : (
            <Spinner />
          )}
        </h2>
        <h1 className={lang === "AR" && "arabic-font arabic-header-1"}>
          {loading ? (
            lang === "EN" ? (
              blogData.secondTitle_en
            ) : (
              blogData.secondTitle_ar
            )
          ) : (
            <Spinner />
          )}
        </h1>
        <h2 className={lang === "EN" ? "thin" : "thin arabic-font ar-thin"}>
          {loading ? (
            lang == "EN" ? (
              blogData.thirdTitle_en
            ) : (
              blogData.thirdTitle_ar
            )
          ) : (
            <Spinner />
          )}
        </h2>
      </div>
    </div>
  );
};

export default BlogHeader;
