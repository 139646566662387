import React, { useState, useContext, useRef } from "react";
import "./filter.css";
import { GlobalContext } from "../../../GlobalContext/GlobalProvider";

import "./list.css";
import { useEffect } from "react";

import axios from "axios";

const Filter = (props) => {
  const { projects, projDataLoad } = useContext(GlobalContext);
  const [search, setSearch] = useState("");
  const [searchText, setSearchText] = useState("");
  const [appearance, setAppearance] = useState(false);
  const [locations, filteredLocations] = useState([]);
  const [loadingFiltered, setLoadingFiltered] = useState(false);
  const [filteredProjs, setFilteredProjs] = useState([]);
  const [titles, setTitles] = useState([]);
  const listItem = useRef();

  useEffect(() => {
    props.filtered(filteredProjs);
  }, [filteredProjs]);

  function filterProjects() {
    const filteredProjects = projects.filter((proj) => {
      return (
        (search &&
          proj &&
          proj.location &&
          proj.location.toLowerCase().includes(search.toLowerCase())) ||
        proj.title_en.toLowerCase().includes(search.toLowerCase())
      );
    });
    const filteredTitles = projects.filter((proj) => {
      return (
        searchText &&
        proj &&
        proj.title_en &&
        proj.title_en.toLowerCase().includes(searchText.toLowerCase())
      );
    });
    setTitles(filteredTitles);
    filteredLocations(filteredProjects);
  }

  function filteredSetter(e) {
    e.preventDefault();
    props.active(true);

    axios
      .get(
        `https://admin.arcis.ge/api/search?&type_id=&location=${searchText}&type_of_proccessing=`
      )
      .then((res) => {
        props.loader(true);
        console.log(res.data.data);
        if (res.data.data) setFilteredProjs(res.data.data);
      })
      .catch((err) => console.log(err));

    axios
      .get(
        `https://admin.arcis.ge/api/search?&type_id=&searchText=${search}&type_of_proccessing=`
      )
      .then((res) => {
        if (res.data.data) setFilteredProjs(res.data.data);
      })
      .catch((err) => console.log(err));
  }

  function searchHandler(e) {
    const searchTerm = e.target.value;
    setSearch(searchTerm);
    setSearchText(searchTerm);
    filterProjects();
  }

  function listHandler(e) {
    setSearch(e.target.textContent);
    filteredLocations([]);
    setTitles([]);
  }

  function searchResultAppear() {
    setAppearance(true);
  }

  return (
    <form
      className="form-flex-container"
      action={`https://admin.arcis.ge/api/search?location=${searchText}&type_id=&type_of_proccessing=&searchText=${search}`}
      method="GET"
    >
      <input
        onFocus={searchResultAppear}
        // onBlur={appearanceOut}
        type="search"
        value={search}
        onChange={searchHandler}
        placeholder={
          props.lang === "AR" ? "المكان المطلوب ؟" : "Desired location..?"
        }
        name="Location"
        className="search-bar"
        id="Search-bar"
      />
      <ul
        className={
          appearance
            ? props.lang === "EN"
              ? "results"
              : "results ar-results"
            : "results not-active"
        }
      >
        {search &&
          locations.map(({ location }, idx) => {
            return (
              <li key={idx} ref={listItem} onClick={listHandler}>
                {location}
              </li>
            );
          })}
        {search &&
          titles.map(({ title_en }, idx) => {
            return (
              <li key={idx} ref={listItem} onClick={listHandler}>
                {title_en}
              </li>
            );
          })}
      </ul>
      <button
        onClick={filteredSetter}
        type="submit"
        className={
          props.lang === "EN"
            ? "btn-style btn-grid"
            : "btn-style btn-grid arabic-font"
        }
        id="search-btn"
      >
        {props.lang === "EN" ? "Search" : "ابحث"}
      </button>
    </form>
  );
};

export default Filter;
