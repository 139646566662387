import React, { useState } from "react";
import "./SideBar.css";
import Social from "./image/social-media.png";
const SideBar = () => {
  const [iconState, setState] = useState(false);
  function changeState() {
    setState(!iconState);
  }

  return (
    <div className="side-bar-container">
      <nav>
        <ul className="social-media-list">
          <li>
            <a href="https://www.facebook.com/manageArcis/?viewas=100000686899395&show_switched_toast=0&show_invite_to_follow=0&show_switched_tooltip=0&show_podcast_settings=0&show_community_review_changes=0&show_community_rollback=0&show_follower_visibility_disclosure=0">
              <i className="fa-brands fa-facebook"></i>
            </a>
          </li>
          <li>
            <a href="https://instagram.com/arcis.development?igshid=ZGUzMzM3NWJiOQ==">
              <i className="fa-brands fa-instagram"></i>
            </a>
          </li>
          <li>
            <a href="https://twitter.com/ArcisGeorgia?t=rKvPPexVpOT1LlamXz_hmg&s=08">
              <i className="fa-brands fa-twitter"></i>
            </a>
          </li>
          <li>
            <a href="https://www.linkedin.com/company/arcis-development/">
              <i className="fa-brands fa-linkedin"></i>
            </a>
          </li>
          <li>
            <a href="https://youtube.com/@ARCIS.DEVELOPMENT">
              <i className="fa-brands fa-youtube"></i>
            </a>
          </li>
          <li>
            <a href="https://vm.tiktok.com/ZSLJVsXc3/">
              <i className="fa-brands fa-tiktok"></i>
            </a>
          </li>
        </ul>
      </nav>
    </div>
  );
};

export default SideBar;
