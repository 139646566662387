import React, { useContext, useEffect, useState } from "react";
import "./Footer.css";
import "./footerForm.css";
import axios from "axios";
import { GlobalContext } from "../GlobalContext/GlobalProvider";

const FooterForm = (props) => {
  const { langState } = useContext(GlobalContext);
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [isCooldown, setCooldown] = useState(false);
  const lang = langState.lang;

  const timeout = 600000;

  function nameHandler(e) {
    setName(e.target.value);
  }

  function phoneHandler(e) {
    setPhone(e.target.value);
  }

  function emailHandler(e) {
    setEmail(e.target.value);
  }

  function messageHandler(e) {
    setMessage(e.target.value);
  }

  function formSubmit(e) {
    e.preventDefault();
    if (name && phone && email && message) {
      const contactMessage = {
        name,
        phone,
        email,
        message,
      };

      axios
        .post("https://admin.arcis.ge/api/Contactus/store", contactMessage)
        .then((response) => console.log(response.data))
        .catch((err) => console.log(err));
      setName("");
      setEmail("");
      setMessage("");
      setPhone("");
      props.messageState(true);
      props.emptyField(false);

      localStorage.setItem("cooldown", true);
    } else {
      props.emptyField(true);
    }
  }
  let state = JSON.parse(localStorage.getItem("cooldown"));

  useEffect(() => {
    setCooldown(state);
    let timer = setInterval(() => {
      setCooldown(false);
      localStorage.removeItem("cooldown");
    }, timeout);

    return () => clearInterval(timer);
  });

  return (
    <div className="footer-form-container footer-form-wrapper">
      <div
        className={
          lang === "EN"
            ? "footer-form-headers"
            : "footer-form-headers arabic-font"
        }
      >
        <h1>{lang === "EN" ? "Contact Us" : "تواصل معنا"}</h1>
        <h3>
          {lang === "EN"
            ? "Reach us with your email"
            : "من خلال بريدك الالكتروني"}
        </h3>
      </div>
      <form onSubmit={formSubmit}>
        <input
          // required
          type="text"
          onChange={nameHandler}
          value={name}
          placeholder="Your Name"
        />
        <input
          // required
          // type="email"
          onChange={emailHandler}
          value={email}
          placeholder="Your Email"
        />
        <input
          // required
          type="number"
          value={phone}
          onChange={phoneHandler}
          className="number-input"
          placeholder="Your Phone Number"
        />
        <div className="input-full-width">
          <input
            // required
            onChange={messageHandler}
            value={message}
            type="text"
            className="text-area"
            placeholder="Message in short words..."
          />
          {isCooldown ? (
            <div className="footer-form-btn btn-style timeout-mssg">
              <p>wait for 10 mins so you can resend a message</p>
            </div>
          ) : (
            <button className="footer-form-btn btn-style">
              <i class="fa-solid fa-arrow-right"></i>
            </button>
          )}
        </div>
      </form>
    </div>
  );
};

export default FooterForm;
