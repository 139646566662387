import React, { useContext } from "react";
import "./maingoal.css";
import BgImage from "./images/goalImg.png";
import { Parallax } from "react-parallax";
import { useInView } from "react-intersection-observer";
import { GlobalContext } from "../../../GlobalContext/GlobalProvider";
import Spinner from "../../../spinner/Spinner";
const MainGoal = () => {
  const { langState, loading, lookupsData } = useContext(GlobalContext);

  const [ref, inView] = useInView();

  const lang = langState.lang;
  const aboutData = lookupsData.AboutUs;
  console.log(aboutData);
  return (
    <div className="main-goal-container">
      <div
        className={
          lang === "EN"
            ? "goals-background"
            : "goals-background arabic-font goal-panel-ar"
        }
      >
        <h3 className="text-goal">{lang === "EN" ? "Goal" : "الهدف"}</h3>
        <h1 className="text-goal-hdln">
          {lang === "EN" ? "What we do ?" : "ماذا نفعل؟"}
        </h1>
      </div>

      <div
        ref={ref}
        className={
          inView ? "goal-para-wrapper full-height" : "goal-para-wrapper"
        }
      >
        <div
          className={
            lang === "EN"
              ? "main-goal-para"
              : "main-goal-para arabic-font arabic-goal"
          }
        >
          <h1>{lang === "EN" ? "Our main goal is " : "هدفنا الاساسي هو"}</h1>
          {lang === "EN" ? (
            <p>{loading ? aboutData.goal_en : <Spinner />}</p>
          ) : (
            <p>{loading ? aboutData.goal_ar : <Spinner />}</p>
          )}
        </div>
      </div>
    </div>
  );
};

export default MainGoal;
/**
 * Created by MeePwn
 * https://github.com/maybewaityou
 *
 * description:
 * 
 * 
 * 
 *      Our main goal is the satisfaction of our own clients because we
            really do care about you living in your dream house we work day and
            night to achieve your architectural desire our designs are simple,
            elegant, modern yet will fulfill your imagination.
 *
 */
