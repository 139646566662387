import React from "react";

export const indexReducer = (state, action) => {
  switch (action.type) {
    case "CLICKED":
      return (state = action.id);
    default:
      return state;
  }
};
