import React, { useContext, useState } from "react";
import "./ReviewModal.css";
import { GlobalContext } from "../../../GlobalContext/GlobalProvider";
import axios from "axios";
import { useEffect } from "react";
const ReviewModal = (props) => {
  const { langState } = useContext(GlobalContext);
  const lang = langState.lang;
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [country, setCountry] = useState("");
  const [message, setComment] = useState("");
  const [email, setEmail] = useState("");
  const [project_id, setProj] = useState(props.projId);
  const [commentEval, evalSetter] = useState(false);
  let evalTimeout = 600000;

  function nameHandler(e) {
    setName(e.target.value);
  }

  function phoneHandler(e) {
    setPhone(e.target.value);
  }
  function commentHandler(e) {
    setComment(e.target.value);
  }
  function countryHandler(e) {
    setCountry(e.target.value);
  }
  function emailHandler(e) {
    setEmail(e.target.value);
  }
  function submitHandler(e) {
    e.preventDefault();

    axios
      .post("https://admin.arcis.ge/api/Review/store", {
        email,
        name,
        phone,
        message,
        project_id,
        country,
      })
      .then((response) => console.log(response))
      .catch((err) => console.log(err));

    props.setState(!props.state);
    setName("");
    setCountry("");
    setEmail("");
    setPhone("");
    setComment("");
    evalSetter(true);
    localStorage.setItem("evalState", true);
  }

  let state = JSON.parse(localStorage.getItem("evalState"));

  useEffect(() => {
    evalSetter(state);
    let timer = setTimeout(() => {
      evalSetter(false);
      localStorage.removeItem("evalState");
    }, evalTimeout);
    return () => clearTimeout(timer);
  }, [commentEval]);
  console.log(commentEval);

  function closeHandler() {
    props.modalActiveState(false);
  }

  return (
    <div
      className={
        props.state ? "modal-container modal-active" : "modal-container"
      }
    >
      <button onClick={closeHandler} className="close-modal">
        <i className="fa-solid fa-xmark"></i>
      </button>
      <div className="modal-heading-container">
        <h4
          className={
            lang === "EN" ? "modal-headline" : "modal-headline arabic-font"
          }
        >
          {lang === "EN"
            ? `kindly add a comment ${props.title}`
            : `اترك تعليقا بنائا ${props.title}`}
        </h4>
      </div>
      <form onSubmit={submitHandler} action="url" className="modal-form">
        <input
          type="text"
          value={name}
          onChange={nameHandler}
          nmae="commentor-name"
          placeholder={lang === "EN" ? "Full Name" : "الاسم الثلاثي"}
        />
        <input
          type="number"
          value={phone}
          onChange={phoneHandler}
          name="commentor-number"
          placeholder={lang === "EN" ? "Phone Number" : "رقم التليقون"}
        />
        <input
          type="text"
          value={country}
          onChange={countryHandler}
          name="commentor-country"
          placeholder={lang === "EN" ? "Country" : "اسم البلد"}
        />
        <input
          type="email"
          name="commentor-email"
          value={email}
          onChange={emailHandler}
          placeholder={lang === "EN" ? "Email address" : "البريد الالكتروني"}
        />
        <textarea
          onChange={commentHandler}
          name="project-comment"
          value={message}
          id="comment-area"
          placeholder={lang === "EN" ? "Comment" : "التعليق"}
          cols="30"
          rows="10"
        ></textarea>
        {commentEval ? (
          <h2
            className={
              lang === "EN"
                ? "modal-submit evaluation-comment"
                : "modal-submit evaluation-comment arabic-font"
            }
          >
            {lang == "EN"
              ? "You can comment every 10 mins"
              : "رجاء انتظار عشر دقائق لترك تعليق اخر"}
          </h2>
        ) : (
          <input
            className={
              lang === "EN" ? "modal-submit" : "modal-submit arabic-font"
            }
            type="submit"
            style={{ fontSize: "24px" }}
            value={lang === "EN" ? "Submit" : "تعليق"}
          />
        )}
      </form>
    </div>
  );
};

export default ReviewModal;
