import React, { useRef, useContext, useState, useEffect } from "react";
import ARLang from "./images/ARLang.webp";
import ENLang from "./images/ENLang.png";
import { GlobalContext } from "../GlobalContext/GlobalProvider";
import "./LangBtn.css";
const LangBtnScript = () => {
  const [clickedState, setClick] = useState(false);
  const { dispatch } = useContext(GlobalContext);
  function enHandler() {
    dispatch({ type: "EN" });
  }
  function arHandler() {
    dispatch({ type: "AR" });
  }
  function activateLang() {
    setClick(!clickedState);
  }

  return (
    <div
      className={
        clickedState
          ? "lang-btn-container lang-buttons-container lang-container-active"
          : "lang-btn-container lang-buttons-container"
      }
    >
      <div
        onClick={activateLang}
        className={
          clickedState ? "icon-container active-icon" : "icon-container"
        }
      >
        <i className="fa-solid fa-globe"></i>
      </div>
      <div
        className={
          clickedState ? "btns-wrapper btns-wrapper-active" : "btns-wrapper"
        }
      >
        <div className="lang-container">
          <button onClick={arHandler}>
            <img src={ARLang} width="25px" height="25px" alt="" />
            <span>AR</span>
          </button>
          <button onClick={enHandler}>
            <img src={ENLang} width="25px" height="25px" alt="" />
            <span>EN</span>
          </button>
        </div>
      </div>
    </div>
  );
};

export default LangBtnScript;
