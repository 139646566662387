import React, { useReducer } from "react";

export const LangReducerApp = (state, action) => {
  switch (action.type) {
    case "EN":
      return (state = { lang: "EN" });

    case "AR":
      return (state = { lang: "AR" });

    default:
      return state;
  }
};
