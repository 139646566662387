import React from "react";

export const PropertyReducer = (state, action) => {
  switch (action.type.toUpperCase()) {
    case "ALL":
      return (state = { property: "all" });
    case "RESORTS":
      return (state = { property: "resorts" });
    case "LANDS":
      return (state = { property: "lands" });
    case "UNITS":
      return (state = { property: "units" });
    case "BUILDINGS":
      return (state = { property: "buildings" });
  }
};
