import "./App.css";
import LandingPage from "./Components/IndexPage/LandingPage";
import { Routes, Route, useLocation } from "react-router-dom";
import SideBar from "./Components/SideBar/SideBar";
import Navbar from "./Components/NavBar/Navbar";
import WhatsSide from "./Components/whatsApp/whatsSide";
import Projects from "./Components/ProjectsPage/projects";
import AboutUs from "./Components/AboutUs/AboutUs";
import GlobalContextProvider from "./Components/GlobalContext/GlobalProvider";
import LangBtnScript from "./Components/LangBtn/LangBtnScript";
import Reviews from "./Components/ReviewsComp/Reviews";

import { useEffect, useState, useLayoutEffect } from "react";
// import BrowserHistory from "react-router-dom";
import RevsNavbar from "./Components/RevsNavbar/RevsNavbar";
import Blog from "./Components/BlogPosts/Blog";
import ProjectsData from "./Components/ProjectsPage/projComponents/ProjectsDataPage/ProjectsData";

function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}

function App() {
  let location = useLocation();

  return (
    <>
      <GlobalContextProvider>
        {location.pathname === "/Reviews" ||
        location.pathname === "/ProjectsData" ? (
          <RevsNavbar />
        ) : (
          <Navbar />
        )}
        <SideBar />
        <WhatsSide />

        <div className="app-container">
          <LangBtnScript />
          <ScrollToTop />
          <Routes>
            <Route path="/" element={<LandingPage />} />
            <Route path="/Projects" element={<Projects />} />
            <Route path="/About" element={<AboutUs />} />
            <Route path="/Reviews" element={<Reviews />} />
            <Route path="/Blog" element={<Blog />} />
            <Route path="/ProjectsData" element={<ProjectsData />} />
          </Routes>
        </div>
      </GlobalContextProvider>
    </>
  );
}

export default App;
