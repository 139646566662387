import React from "react";
import "./secondSection.css";
import SecondImage from "./imageScnd/historyscndImage.png";
import { useInView } from "react-intersection-observer";
import { Background, Parallax, ParallaxLayer } from "react-parallax";
import Spinner from "../../../../spinner/Spinner";
const SecondComp = (props) => {
  const [ref, inView] = useInView();
  const lang = props.lang;
  const aboutData = props.data.AboutUs;
  return (
    <div className="second-history-container">
      <div className="second-history-grid">
        <div className="second-img-container">
          <Parallax
            strength={100}
            className="second-img"
            bgImage={SecondImage}
          ></Parallax>
        </div>
        <div className="second-para-container">
          {lang === "EN" ? (
            <p
              ref={ref}
              className={inView ? "text-inview text-active" : " text-inview"}
            >
              {props.loading ? aboutData.secondArticle_en : <Spinner />}
            </p>
          ) : (
            <p
              ref={ref}
              className={
                inView ? "text-inview text-active arabic-font" : " text-inview"
              }
            >
              {props.loading ? aboutData.secondArticle_ar : <Spinner />}
            </p>
          )}
        </div>
      </div>
    </div>
  );
};

export default SecondComp;

{
  /* <img src={SecondImage} className="second-img" alt="" /> */
}

/**
 * Created by MeePwn
 * https://github.com/maybewaityou
 *
 * description:
 *
 *       If you want a new home, or an experienced investor, or a real estate
            owner looking for someone to manage your property, Arcis
            Developments has the best experience so far to help you achieve your
            goals,Arcis Development's mission is to provide our clients in
            Georgia with the best real estate services This is by offering a
            variety of high-quality real estate This is done by providing the
            best investment advice and support throughout the process of buying,
            selling or managing your investment We strive to establish long-term
            relationships with our clients by providing them with an exceptional
            investment experience
 * 
 * 
 * 
 */
