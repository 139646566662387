import React, { useContext } from "react";
import FirstComp from "./historyfirstComp/FirstComp";
import SecondComp from "./historySecondComp/SecondComp";
import "./AboutHistory.css";
import { GlobalContext } from "../../../GlobalContext/GlobalProvider";
const AboutHistoryComp = () => {
  const { loading, lookupsData, langState } = useContext(GlobalContext);

  const lang = langState.lang;
  return (
    <div className="history-about-first-container">
      <section
        className={
          lang === "EN"
            ? "company-history-headline"
            : "company-history-headline arabic-heading-about arabic-font"
        }
      >
        <h3> {lang === "EN" ? "Company" : "تاريخ"} </h3>
        <h2>{lang === "EN" ? "History" : "الشركة"}</h2>
      </section>
      <section>
        <div>
          <FirstComp loading={loading} data={lookupsData} lang={lang} />
        </div>
        <div>
          <SecondComp loading={loading} data={lookupsData} lang={lang} />
        </div>
      </section>
    </div>
  );
};

export default AboutHistoryComp;
