import React, { useContext } from "react";
import "./comments.css";
import { Swiper, SwiperSlide } from "swiper/react";
import { useInView } from "react-intersection-observer";
import CommentorsData from "./CommentorsData";

import { Navigation, Pagination, Scrollbar, A11y } from "swiper";
import "swiper/css/pagination";
import "swiper/css";
import { NavLink } from "react-router-dom";
import { GlobalContext } from "../../GlobalContext/GlobalProvider";

const CommentsSection = () => {
  const { comments } = useContext(GlobalContext);
  const commentsGrasp = comments.slice(0, 6);
  console.log(commentsGrasp.length);
  const [ref, inView] = useInView({
    threshold: 0,
  });

  return (
    <div
      className={
        commentsGrasp.length > 0
          ? "comment-section-container"
          : "comment-section-none"
      }
    >
      <div className="headline-text">
        <h1
          ref={ref}
          className={
            inView
              ? "slide-left headline-text-heading"
              : "headline-text-heading"
          }
        >
          Here's a grasp
        </h1>
        <p ref={ref} className={inView ? "text-para slide-left" : "text-para"}>
          of some of our clients testimonials
        </p>
      </div>

      <div className="comment-wrapper full-comment-width">
        <Swiper
          modules={[Pagination]}
          spaceBetween={50}
          slidesPerView={4}
          onSlideChange={() => console.log("slide change")}
          onSwiper={(swiper) => console.log(swiper)}
        >
          {commentsGrasp.map((val, id) => {
            return (
              <SwiperSlide>
                <CommentorsData
                  key={id}
                  comment={val.message}
                  name={val.name}
                  city={val.country}
                />
              </SwiperSlide>
            );
          })}
        </Swiper>
      </div>
      <div className="comment-wrapper tablet-width ">
        <Swiper
          modules={[Pagination]}
          spaceBetween={20}
          slidesPerView={1}
          onSlideChange={() => console.log("slide change")}
          onSwiper={(swiper) => console.log(swiper)}
        >
          {commentsGrasp.map((val, id) => {
            return (
              <SwiperSlide>
                <CommentorsData
                  key={id}
                  comment={val.message}
                  name={val.name}
                  city={val.country}
                />
              </SwiperSlide>
            );
          })}
        </Swiper>
      </div>
      <div className="comment-wrapper mobile-phone-width ">
        <Swiper
          modules={[Pagination]}
          spaceBetween={10}
          slidesPerView={1.1}
          onSlideChange={() => console.log("slide change")}
          onSwiper={(swiper) => console.log(swiper)}
        >
          {commentsGrasp.map((val, id) => {
            return (
              <SwiperSlide>
                <CommentorsData
                  key={id}
                  comment={val.message}
                  name={val.name}
                  city={val.country}
                />
              </SwiperSlide>
            );
          })}
        </Swiper>
      </div>
    </div>
  );
};

export default CommentsSection;
