import React from "react";
import "./Modal.css";
import { useContext } from "react";
import { GlobalContext } from "../../GlobalContext/GlobalProvider";
const Modal = (props) => {
  const { langState } = useContext(GlobalContext);
  const lang = langState.lang;
  function dismissHandler() {
    props.setActiveState(false);
  }
  return (
    <div
      className={
        props.activeState
          ? "modal-container-mssg active-message"
          : "modal-container-mssg"
      }
    >
      <h2 className={lang === "AR" && "arabic-font"}>
        {lang === "EN"
          ? "Thank you for contacting us usually we reply through email within 48hrs"
          : "شكرًا لتواصلكم معنا. عادةً ما نقوم بالرد عبر البريد الإلكتروني في غضون 48 ساعة. "}
      </h2>
      <button onClick={dismissHandler}>
        {lang === "EN" ? "Dismiss" : "تجاهل"}
      </button>
    </div>
  );
};

export default Modal;
