import React from "react";
import "./comments.css";
const Comments = (props) => {
  return (
    <div className="comments-wrapper">
      <i className="fa-solid fa-quote-left "></i>

      <div className="country__name-wrapper">
        <h3>{props.personName}</h3>

        <span>{props.personCountry}</span>
      </div>
      <div className="comment">{props.personComment}</div>
      <div className="comment-date">
        <span>{props.date}</span>
      </div>
      <i className="fa-solid fa-quote-right "></i>
    </div>
  );
};

export default Comments;
