import React from "react";

const Data = [
  {
    id: 1,
    link: "/About",
    picture: <i class="fa-solid fa-user-group"></i>,
    text: "We have more engineers working on your projects",
    button: "Get to know them",
    textAr: "لدينا مهندسين يعملون لارضائك",
    buttonAr: "تعرف عليهم",
  },
  {
    id: 2,
    link: "/Projects",
    picture: <i class="fa-solid fa-building"></i>,
    text: "We have projects all over Georgia",
    button: "See our wonders",
    textAr: "لدينا مشاريع في كل مكان في جيورجيا",
    buttonAr: "شاهد إبداعاتنا",
  },
  {
    id: 3,
    link: "/Reviews",
    picture: <i className="fa-solid fa-face-smile"></i>,
    text: "Our clients are surely happy of our treatment",
    button: "join our community",
    textAr: "لدينا اكثر من عميل سعيد بمعاملتنا",
    buttonAr: "انضم إلى مجتمعنا",
  },
];

export default Data;
