import React, { useContext, useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import "./data.css";
import ReviewModal from "../ProjectReview/ReviewModal";
import { GlobalContext } from "../../../GlobalContext/GlobalProvider";
import axios from "axios";
const ProjectsCards = (props) => {
  const { idDispatcher, langState } = useContext(GlobalContext);

  const [modalState, setModal] = useState(false);
  function reviewHandler() {
    setModal(!modalState);
  }
  const cardsData = props.data;
  const lang = props.lang;

  const image_1 = cardsData.subImages[0]?.url;
  const image_2 = cardsData.subImages[1]?.url;
  const id = cardsData.id;
  function idHandler() {
    idDispatcher({
      type: "CLICKED",
      id,
    });
  }

  return (
    <div className="proj-data-container">
      <ReviewModal
        index={props.index}
        title={lang === "EN" ? cardsData.title_en : cardsData.title_ar}
        modalActiveState={setModal}
        state={modalState}
        setState={setModal}
        lang={lang}
        projId={cardsData.id}
      />
      <div className="overlay proj"></div>
      {cardsData.sold ? <div className="Sold-panel">Sold Out</div> : ""}
      <div
        className={
          modalState ? "overlay-modal overlay-modal-active" : "overlay-modal"
        }
      ></div>
      <div className="data-wrapper">
        <div className="proj-name">
          <h2 className={lang === "AR" && "arabic-font"}>
            {lang === "EN" ? cardsData.title_en : cardsData.title_ar}
          </h2>
        </div>
        <div className="image-wrapper-proj">
          <img
            src={cardsData.mainImage}
            className="image-one proj-image"
            width="100%"
            height="100%"
            alt=""
          />
          <img
            src={image_1}
            className="image-two proj-image"
            width="100%"
            height="100%"
            alt=""
          />
          <img
            src={image_2}
            className="image-three proj-image"
            width="100%"
            height="100%"
            alt=""
          />
        </div>
        <div className="data-wrapper-proj">
          <button
            onClick={reviewHandler}
            className={
              lang === "EN" ? "rev-btn-proj" : "rev-btn-proj rev-ar arabic-font"
            }
          >
            {lang === "EN" ? "Review" : "تعليق"}
          </button>

          <h4 className={lang === "EN" ? "proj-type" : "proj-type arabic-font"}>
            {lang === "EN" ? cardsData.type_en : cardsData.type_ar}
          </h4>
          <p
            className={
              lang === "EN" ? "proj-details" : "proj-details arabic-font"
            }
          >
            {lang === "EN"
              ? cardsData.description_en
              : cardsData.description_ar}
          </p>
          <div className="proj-info">
            <div className="info">
              <h4>Location</h4>
              <i class="fa-solid fa-location-dot"></i>
              {cardsData.location}
            </div>
            <div className="info">
              <h4>Floors</h4>
              <span>{cardsData.floors}</span>
            </div>
            <div className="proj-space info">
              <h4>Space</h4>
              <span>{cardsData.space}sqm</span>
            </div>
          </div>
          <NavLink to={"/ProjectsData"}>
            <button
              className={
                lang === "EN" ? "cards-view-btn" : "cards-view-btn arabic-font"
              }
              onClick={() => idHandler()}
            >
              {lang === "EN" ? "View Project" : "صفحة المشروع"}
            </button>
          </NavLink>
        </div>
      </div>
    </div>
  );
};

export default ProjectsCards;
