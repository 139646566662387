import React, { useState, useRef, useContext } from "react";
import { NavLink } from "react-router-dom";
import "./project.css";
import PanelData from "./PanelData";
import { GlobalContext } from "../../GlobalContext/GlobalProvider";

const ProjectPanel = () => {
  const [count, setCount] = useState(1);
  const [isButtonDisabled, setButtonDisabled] = useState(false);
  const { langState } = useContext(GlobalContext);
  const lang = langState.lang;
  const timeoutRef = useRef(null);

  function countPlusHandler() {
    if (!isButtonDisabled) {
      clearTimeout(timeoutRef.current);
      setButtonDisabled(true);
      setCount((prevCount) => {
        let newCount = prevCount + 1;
        if (newCount > 4) {
          newCount = 1;
        }
        return newCount;
      });
      timeoutRef.current = setTimeout(() => {
        setButtonDisabled(false);
      }, 1200);
      console.log(timeoutRef.current);
    }
  }

  function countMinusHandler() {
    if (!isButtonDisabled) {
      clearTimeout(timeoutRef.current);
      setButtonDisabled(true);
      setCount((prevCount) => {
        let newCount = prevCount - 1;
        if (newCount < 1) {
          newCount = 4;
        }
        return newCount;
      });
      timeoutRef.current = setTimeout(() => {
        setButtonDisabled(false);
      }, 1200);
    }
  }

  return (
    <div className="panel-wrapper">
      <button className="arrow left" onClick={countMinusHandler}>
        <div>
          <i className="fa-solid fa-chevron-left"></i>
        </div>
      </button>
      {PanelData.map(
        ({ id, image, title_EN, title_AR, article_EN, article_AR }, index) => {
          return (
            <div
              className={
                id === count
                  ? "panel-grid-wrapper active-proj-panel"
                  : "panel-grid-wrapper "
              }
            >
              <div
                className="panel-container"
                style={{ backgroundImage: `url(${image})` }}
              >
                <button
                  onClick={countPlusHandler}
                  className="next-btn proj-panel-btn"
                >
                  <i className="fa-solid fa-chevron-right arrow-right"></i>
                </button>
                <div
                  className={
                    lang === "AR"
                      ? "text-proj arabic-font arabic-text-proj"
                      : "text-proj"
                  }
                >
                  <h2>{lang === "AR" ? title_AR : title_EN}</h2>
                  <h1>{lang === "AR" ? article_AR : article_EN}</h1>
                </div>
                <button
                  className={
                    lang === "EN" ? "panel-btn" : "panel-btn arabic-font"
                  }
                >
                  <NavLink to={"/Projects"}>
                    <span
                      className={
                        lang === "EN" ? "btn-text" : "btn-text arabic-font"
                      }
                    >
                      {lang === "EN" ? "Visit Projects" : "المشاريع"}
                    </span>
                  </NavLink>
                </button>
              </div>
              <button
                onClick={countMinusHandler}
                className="prev-btn proj-panel-btn"
              >
                <i className="fa-solid fa-chevron-left"></i>
              </button>
            </div>
          );
        }
      )}
      <button className="arrow right" onClick={countPlusHandler}>
        <div>
          <i className="fa-solid fa-chevron-right arrow-right"></i>
        </div>
      </button>
    </div>
  );
};

export default ProjectPanel;
