import React, { useContext } from "react";
import "./events.css";
import { GlobalContext } from "../../GlobalContext/GlobalProvider";
import { NavLink } from "react-router-dom";
const Events = (props) => {
  const { idDispatcher } = useContext(GlobalContext);
  const date = new Date(props.date);
  let month = date.toLocaleString("default", { month: "long" });
  let day = date.toLocaleString("default", { day: "numeric" });
  const lang = props.lang;
  const id = props.id;
  console.log(id);
  function idHandler() {
    idDispatcher({
      type: "CLICKED",
      id,
    });
  }

  return (
    <div className="events-container">
      <div className="events-wrapper">
        <div className="date-image-container data-blog-wrapper">
          <div className="date-container">
            <span className="small">Delivery Date</span>
            <div className="month">{month}</div>
            <div className="line-seperator"></div>
            <div className="day">{day}</div>
          </div>
          <div className="image-container">
            <img src={props.image} width="100%" height="100%" alt="" />
          </div>
        </div>
        <div className="details-container data-blog-wrapper">
          <div className="titles">
            <h2 className={lang == "EN" ? "title" : "title arabic-font"}>
              {props.title}
            </h2>
          </div>
          <div className="paragraph-wrapper-blog">
            <p
              className={
                lang === "EN" ? "event-para" : "event-para arabic-font"
              }
            >
              {props.subTitle}
            </p>
          </div>
          <div className="blog-container-btns">
            <NavLink to={"/ProjectsData"}>
              <button
                onClick={idHandler}
                className={lang === "EN" ? "view-btn" : "view-btn arabic-font"}
              >
                {lang === "EN" ? "View Project" : "عرض المشروع"}
              </button>
            </NavLink>

            <button
              className={
                lang === "EN"
                  ? "review-btn-blog"
                  : "review-btn-blog arabic-font"
              }
            >
              {lang === "EN" ? "Leave a comment" : "اترك تعليقا"}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Events;
