import React from "react";
import "./firstSection.css";
import FirstImage from "./imageFrst/historyfrstImage.png";
import { useInView } from "react-intersection-observer";
import { Background, Parallax, ParallaxLayer } from "react-parallax";
import Spinner from "../../../../spinner/Spinner";
const FirstComp = (props) => {
  const [ref, inView] = useInView();

  const aboutData = props.data.AboutUs;
  const lang = props.lang;

  return (
    <div className="first-section-container" id="first-history">
      <div className="first-about-grid">
        <div className="First-para-container">
          {lang === "EN" ? (
            <p
              ref={ref}
              className={inView ? "text-inter text-appear" : "text-inter"}
            >
              {props.loading ? aboutData.firstArticle_en : <Spinner />}
            </p>
          ) : (
            <p
              ref={ref}
              className={
                inView ? "text-inter text-appear arabic-font" : "text-inter"
              }
            >
              {props.loading ? aboutData.firstArticle_ar : <Spinner />}
            </p>
          )}
        </div>
        <div className="First-image-container">
          <Parallax
            className="first-img"
            strength={100}
            bgImage={FirstImage}
          ></Parallax>
        </div>
      </div>
    </div>
  );
};

export default FirstComp;
/*  <strong> Arcis Development</strong> Company has great experience in
the Georgian real estate market, as it has been operating for more
than 6 years, which led to the company acquiring throughout this
time the necessary experience in providing high-quality real estate
services to clients in the field of real estate investment. In
addition to real estate services, the company offers a range of
specialized services to assist customers such as property management
services, as well as legal and financial advice in the field of real
estate investment .....*/
