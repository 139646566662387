import React, { useContext } from "react";
import "./AboutHeader.css";
import { GlobalContext } from "../../../GlobalContext/GlobalProvider";
import Spinner from "../../../spinner/Spinner";
const AboutHeader = () => {
  const { lookupsData, loading, langState } = useContext(GlobalContext);
  console.log(lookupsData.AboutUs);
  console.log(loading);
  const lang = langState.lang;
  return (
    <div className="about-header-container">
      {loading ? (
        <div
          className={
            lang === "AR"
              ? "headlines arabic-font ar-about-header"
              : "headlines "
          }
        >
          <h2 className="headline-1">
            {lang === "EN"
              ? lookupsData.AboutUs.firstTitle_en
              : lookupsData.AboutUs.firstTitle_ar}
          </h2>
          <h1 className="headline-2">
            {lang === "EN"
              ? lookupsData.AboutUs.secondTitle_en
              : lookupsData.AboutUs.secondTitle_ar}
          </h1>
          <h2 className="headline-3">
            {lang == "EN"
              ? lookupsData.AboutUs.thirdTitle_en
              : lookupsData.AboutUs.thirdTitle_ar}
          </h2>
        </div>
      ) : (
        <Spinner />
      )}

      <div
        className={
          lang === "AR"
            ? "para-container arabic-font ar-description"
            : "para-container"
        }
      >
        <p>
          {lang === "AR"
            ? "أننا نهتم برغباتك في الحصول على منزل الأحلام، هنا ستتعرف علينا بشكل أفضل."
            : "Because we care, about your desires in obtaining a dream house, Here you’ll get to know us better."}
        </p>
      </div>
      <div className="scroll-bar about-scroll">
        <div className="scroll"></div>
        <a href="#first-history">Scroll Down</a>
      </div>
    </div>
  );
};

export default AboutHeader;
