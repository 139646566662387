import React from "react";
import CommentBody from "./CommentBody";
import CommentBodyFilter from "./CommentBodyFilter";
import "./body.css";

const ReviewsBody = () => {
  return (
    <div className="reviews-body-container">
      <div className="comment-filter-wrapper container-wrapper">
        <CommentBodyFilter />
      </div>
      <div className="comment-body-wrapper container-wrapper">
        <CommentBody />
      </div>
    </div>
  );
};

export default ReviewsBody;
