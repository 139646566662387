import React, { useReducer, useState, createContext, useEffect } from "react";
import { LangReducerApp } from "./AppReducer";

import { CommentReducer } from "./CommentReducer";
import { FilterReducer } from "./FilterReducer";
import { PropertyReducer } from "./PropertyReducer";
import ProjectsReducer from "./ProjectsReducer";
import axios from "axios";
import { indexReducer } from "./indexReducer";
export const GlobalContext = createContext();

const idObj = 12;
const langObj = { lang: "EN" };
const filterStateObj = "all";
const propertyFilter = { property: "all" };
const GlobalProvider = (props) => {
  const [projects, setProjects] = useState([]);
  const [langState, dispatch] = useReducer(LangReducerApp, langObj);

  const [lookupsData, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [projLoading, setLoadingProj] = useState(false);
  const [projDataLoad, setProjDataLoad] = useState(false);
  const [projData, setDataProj] = useState([]);

  const [projComments, setCommentsProj] = useState([]);
  const [pageId, idDispatcher] = useReducer(indexReducer, idObj, () => {
    const projectId = localStorage.getItem("pageId");
    return projectId ? projectId : idObj;
  });
  const [projectIdx, setIndex] = useState({ id: 0 });
  const [commentLoader, setCommentLoader] = useState(false);
  const [comments, setComments] = useState([]);
  const [projectMeta, setProjectsMeta] = useState([]);

  const [mainProjFiltered, mainProjDispatcher] = useReducer(
    ProjectsReducer,
    []
  );
  console.log(lookupsData);
  useEffect(() => {
    axios
      .get("https://admin.arcis.ge/api/GeneralReview")
      .then((response) => {
        setCommentLoader(true);

        return setComments(response.data.response.data);
      })
      .catch((err) => console.log(`Catched this error while fetching ${err}`));
    setCommentLoader(false);
  }, []);

  useEffect(() => {
    localStorage.setItem("pageId", pageId);
    console.log(localStorage.getItem("pageId"));
  }, [pageId]);

  useEffect(() => {
    axios
      .get(`https://admin.arcis.ge/api/Projects`)
      .then((res) => {
        setLoadingProj(true);
        setProjects(res.data.response.data);
      })
      .catch((err) => console.log(err));
    setLoadingProj(false);
  }, []);

  useEffect(() => {
    axios
      .get("https://admin.arcis.ge/api/lookups")

      .then((res) => {
        setLoading(true);
        console.log(res.data.data);
        return setData(res.data.data);
      })
      .catch((err) => alert(err));

    setLoading(false);
  }, []);
  useEffect(() => {
    if (pageId) {
      axios
        .get(`https://admin.arcis.ge/api/project/${pageId}`)

        .then((response) => {
          setProjDataLoad(true);

          setDataProj(response.data.data);
        })
        .catch((err) => console.log(err));
    }
    setProjDataLoad(false);
  }, [pageId]);
  function clickHandler(idx) {
    setIndex({ id: idx });
  }
  useEffect(() => {
    if (pageId) {
      axios
        .get(`https://admin.arcis.ge/api/ProjectReview?project_id=${pageId}`)

        .then((response) => setCommentsProj(response.data.data))
        .catch((err) => console.log(err));
    }
  }, [pageId]);
  const [property, propertyDispatcher] = useReducer(
    PropertyReducer,
    propertyFilter
  );
  const [filterState, stateDispatcher] = useReducer(FilterReducer, "all");

  useEffect(() => {
    axios
      .get("https://admin.arcis.ge/api/Projects")
      .then((res) => {
        setLoadingProj(true);
        setProjectsMeta(res.data.response.meta);
        setProjects(res.data.response.data);
      })
      .catch((err) => console.log(err));
    setLoadingProj(false);
  }, []);

  return (
    <GlobalContext.Provider
      value={{
        langState,
        dispatch,

        stateDispatcher,

        propertyDispatcher,
        property,
        projectMeta,
        comments,
        projects,
        filterState,
        clickHandler,
        projectIdx,
        lookupsData,
        loading,
        projLoading,
        idDispatcher,
        projData,
        projDataLoad,
        projComments,
        mainProjDispatcher,
        mainProjFiltered,
        filterState,
      }}
    >
      {props.children}
    </GlobalContext.Provider>
  );
};

export default GlobalProvider;
