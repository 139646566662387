import React, { useContext, useState } from "react";
import Events from "./Events";

import { GlobalContext } from "../../GlobalContext/GlobalProvider";
const EventsParent = () => {
  const [filter, setFilter] = useState("all");
  const { lookupsData, langState, loading } = useContext(GlobalContext);
  const blogProjects = lookupsData.BlogProjects;
  const lang = langState.lang;
  const [filterState, setFilterState] = useState(false);
  let filteredBlog;
  let eventsComponents;
  console.log(filter);
  if (filter == "all") {
    eventsComponents =
      loading &&
      blogProjects.map(
        ({
          id,
          mainImage,
          title_en,
          title_ar,
          description_en,
          description_ar,
          date,
        }) => {
          return (
            <Events
              key={id}
              id={id}
              title={lang === "EN" ? title_en : title_ar}
              subTitle={lang === "EN" ? description_en : description_ar}
              date={date}
              image={mainImage}
              lang={lang}
            />
          );
        }
      );
  } else {
    filteredBlog =
      loading &&
      blogProjects.filter((blog) => {
        return blog.type_of_proccessing === filter;
      });

    eventsComponents = filteredBlog.map(
      ({
        id,
        mainImage,
        title_en,
        title_ar,
        description_en,
        description_ar,
        date,
      }) => {
        return (
          <Events
            key={id}
            id={id}
            title={lang === "EN" ? title_en : title_ar}
            subTitle={lang === "EN" ? description_en : description_ar}
            date={date}
            image={mainImage}
            lang={lang}
          />
        );
      }
    );
  }
  if (blogProjects.length < 1 || filteredBlog < 1) {
    eventsComponents = "No Blogs to be displayed";
  }

  function checkValue(e) {
    setFilter(e.target.value);
  }

  function openHandler() {
    setFilterState(!filterState);
  }
  return (
    <div className="events-parent-container">
      <div className="blog-section-header">
        <div>
          <h2>Upcoming Events</h2>
        </div>
        <div className="blog-filter">
          <div className="list-filter">
            <div
              className={
                filterState
                  ? "list-blog-wrapper active-open"
                  : "list-blog-wrapper"
              }
            >
              <div className="list-tags">
                <button
                  className="list-filter-btn"
                  onClick={checkValue}
                  value="all"
                >
                  All
                </button>
                <button
                  className="list-filter-btn"
                  onClick={checkValue}
                  value="rent"
                >
                  Rent
                </button>
                <button
                  className="list-filter-btn"
                  onClick={checkValue}
                  value="purchase"
                >
                  purchase
                </button>
              </div>
            </div>
            <div onClick={openHandler} className="list-button">
              <i class="fa-solid fa-list"></i>
            </div>
          </div>
        </div>
      </div>
      <div className={"blogs-container blogs-container-responsive"}>
        {eventsComponents}
      </div>
    </div>
  );
};

export default EventsParent;
