import React, { useContext, useEffect, useState } from "react";
import "./Projects.css";
import { GlobalContext } from "../../../GlobalContext/GlobalProvider";
import ProjectsCards from "./ProjectsCards";
import Spinner from "../../../spinner/Spinner";
import ReactPaginate from "react-paginate";
import axios from "axios";

const Projects = () => {
  const { langState, clickHandler, mainProjFiltered } =
    useContext(GlobalContext);
  const [currentItems, setCurrentItems] = useState([]);
  const [projects, setProjects] = useState([]);
  const [projLoading, setLoadingProj] = useState(false);
  const [id, setPageId] = useState(1);
  const [projectMeta, setProjectMeta] = useState({});
  function pageHandlerIncrement() {
    if (projectMeta.last_page != projectMeta.current_page) {
      setPageId((prevCount) => prevCount + 1);
    }
  }

  function pageHandlerDecrement() {
    if (projectMeta.current_page > 1) {
      setPageId((prevCount) => prevCount - 1);
    } else {
      setPageId(1);
    }
  }
  useEffect(() => {
    axios
      .get(`https://admin.arcis.ge/api/Projects?page=${id}`)
      .then((res) => {
        setLoadingProj(true);
        setProjectMeta(res.data.response.meta);
        setProjects(res.data.response.data);
      })
      .catch((err) => console.log(err));
    setLoadingProj(false);
  }, [id]);

  const lang = langState.lang;
  const [info, setInfo] = useState(false);

  function infoHandler() {
    setInfo(!info);
  }

  let projectsFiles = projects.map(
    (
      {
        id,
        date,
        description_ar,
        description_en,
        mainImage,
        subImages,
        title_ar,
        title_en,
        type_of_processing,
        type_ar,
        type_en,
        floors,
        location,
        space,
        sold,
      },
      index
    ) =>
      projLoading ? (
        <ProjectsCards
          key={id}
          index={index}
          clickHandler={clickHandler}
          data={{
            id,
            date,
            description_ar,
            description_en,
            mainImage,
            subImages,
            title_ar,
            title_en,
            type_of_processing,
            type_ar,
            type_en,
            floors,
            location,
            space,
            sold,
          }}
          lang={lang}
        />
      ) : (
        <div className="spinner-container">
          <Spinner />
        </div>
      )
  );

  if (mainProjFiltered.length) {
    projectsFiles = mainProjFiltered.length ? (
      mainProjFiltered.map(
        (
          {
            id,
            date,
            description_ar,
            description_en,
            mainImage,
            subImages,
            title_ar,
            title_en,
            type_of_processing,
            type_ar,
            type_en,
            floors,
            location,
            space,
            sold,
          },
          index
        ) =>
          projLoading ? (
            <ProjectsCards
              key={id}
              index={index}
              clickHandler={clickHandler}
              data={{
                id,
                date,
                description_ar,
                description_en,
                mainImage,
                subImages,
                title_ar,
                title_en,
                type_of_processing,
                type_ar,
                type_en,
                floors,
                location,
                space,
                sold,
              }}
              lang={lang}
            />
          ) : (
            <div className="spinner-container">
              <Spinner />
            </div>
          )
      )
    ) : (
      <h2 className="no-results-proj">No Search Results</h2>
    );
  }

  return (
    <div className="projects-cards-container">
      <div
        className={
          lang === "EN"
            ? " projects-introduction"
            : "projects-introduction arabic-font"
        }
      >
        <div className="grid-item">
          <h2
            className={
              lang === "EN" ? "heading-proj" : "heading-proj arabic-font"
            }
          >
            {lang === "EN" ? "Our projects introduction" : "مقدمة المشاريع"}
          </h2>
        </div>
        <div className="grid-item list-item">
          <h3
            className={
              lang === "EN" ? "grid-items-hdln" : "grid-items-hdln arabic-font"
            }
            onClick={infoHandler}
          >
            <div>
              {lang === "EN"
                ? "Why invest in georgia?"
                : "لماذا الاستثمار في جيورجيا ؟"}
            </div>
            <i
              className={
                info
                  ? "fa-solid fa-plus plus-mark plus-mark-actv"
                  : "fa-solid fa-plus plus-mark"
              }
            ></i>
          </h3>
          <div
            className={
              info ? "grid-list-wrapper grid-list-active" : "grid-list-wrapper"
            }
          >
            <ul
              className={lang === "EN" ? "grid-list" : "grid-list arabic-font"}
            >
              <li className={lang === "EN" ? "" : "arabic-font align-right"}>
                {lang === "EN"
                  ? "Visa free mode for 94 countries"
                  : "الوضع الحر للتأشيرة لـ 94 دولة"}
              </li>
              <li className={lang === "EN" ? "" : "arabic-font align-right"}>
                {lang === "EN"
                  ? "Absence of language barrier"
                  : "غياب حاجز اللغة"}
              </li>
              <li className={lang === "EN" ? "" : "arabic-font align-right"}>
                {lang === "EN"
                  ? " Absence of taxes for real estate"
                  : "غياب الضرائب على العقارات"}
              </li>
              <li className={lang === "EN" ? "" : "arabic-font align-right"}>
                {lang === "EN"
                  ? "Simple procedure of real estate registration ownership takes just 30 minutes"
                  : "إجراء بسيط لتسجيل ملكية العقار يستغرق فقط 30 دقيقة"}
              </li>
              <li className={lang === "EN" ? "" : "arabic-font align-right"}>
                {lang === "EN"
                  ? "Georgia is in the TOP 10 of safest countries in the world"
                  : "جورجيا في قائمة أعلى 10 دول آمنة في العالم"}
              </li>
              <li className={lang === "EN" ? "" : "arabic-font align-right"}>
                {lang === "EN"
                  ? "Ideal ecological conditions for life"
                  : "الظروف البيئية المثالية للحياة"}
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="cards-wrapper">{projectsFiles}</div>
      <div className="pagination-container">
        <div
          className={
            projectMeta.current_page > 1
              ? "prev-page proj-pag-container"
              : "prev-page proj-pag-container disabled-pagination"
          }
          onClick={pageHandlerDecrement}
        >
          <i class="fa-solid fa-arrow-left"></i>
          <span>Prev</span>
        </div>
        <div className="pagination-subData">
          <h4>
            page {projectMeta.current_page} of {projectMeta.last_page}
          </h4>
        </div>
        <div
          className={
            projectMeta.current_page === projectMeta.last_page
              ? "next-page proj-pag-container disabled-pagination"
              : "next-page proj-pag-container"
          }
          onClick={pageHandlerIncrement}
        >
          <span>Next</span>
          <i class="fa-solid fa-arrow-right"></i>
        </div>
      </div>
    </div>
  );
};

export default Projects;
