import React, { useContext } from "react";
import "./sect.css";
import { NavLink } from "react-router-dom";
import { useInView } from "react-intersection-observer";
import { GlobalContext } from "../../GlobalContext/GlobalProvider";

const DataComp = (props) => {
  const { langState } = useContext(GlobalContext);
  const lang = langState.lang;

  const { ref, inView } = useInView({
    threshold: 0,
  });

  return (
    <div
      ref={ref}
      className={inView ? "data-container animation-slide" : "data-container"}
    >
      <div className="icon">{props.picture}</div>
      <div className={lang === "EN" ? "text" : "text arabic-font"}>
        {lang === "EN" ? props.text : props.textAr}
      </div>
      <div>
        <NavLink to={props.link}>
          <button
            className={
              lang === "EN"
                ? "btn-underline btn-text"
                : "btn-underline btn-text arabic-font"
            }
          >
            {lang === "EN" ? props.button : props.buttonAr}
          </button>
        </NavLink>
      </div>
    </div>
  );
};

export default DataComp;
