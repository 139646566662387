import React, { useContext } from "react";
import "./body.css";
import Filter from "./Filter/Filter";
import { GlobalContext } from "../../../GlobalContext/GlobalProvider";
const CommentBodyFilter = () => {
  const { langState } = useContext(GlobalContext);
  return (
    <div className="comment-filter-container">
      <div className="filter-header">
        <h2 className={langState.lang === "AR" && "arabic-font"}>
          {" "}
          {langState.lang === "EN" ? "Filter" : "فلتر"}
        </h2>
      </div>
      <div>
        <Filter />
      </div>
    </div>
  );
};

export default CommentBodyFilter;
