import React from "react";
import "./reviews.css";
import RvwsHeader from "./ReviewsComps/ReviewsHeader/RvwsHeader";
import ReviewsForm from "./ReviewsComps/ReviewsForm/ReviewsForm";
import CommentSection from "./ReviewsComps/ReviewsForm/CommentSection";
import ReviewsBody from "./ReviewsComps/CommentBody/ReviewsBody";
import Footer from ".././Footer/Footer";
const Reviews = () => {
  return (
    <div>
      <RvwsHeader />
      <main className="reviews-main">
        <CommentSection />
        <ReviewsBody />
      </main>
      <Footer />
    </div>
  );
};

export default Reviews;
