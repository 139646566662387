import React, { useContext, useState } from "react";
import "./Footer.css";
import Logo from "./image/FooterLogo.png";
import FooterForm from "./FooterForm";
import Phone from "./image/ic_baseline-local-phone.png";
import Modal from "./ContactModal/Modal";
import Marker from "./image/material-symbols_location-on.png";
import { GlobalContext } from "../GlobalContext/GlobalProvider";

const Footer = () => {
  const { langState } = useContext(GlobalContext);
  const [messageState, setMessageState] = useState(false);
  const [emptyField, emptyFieldSetter] = useState(false);
  const lang = langState.lang;
  return (
    <div className="footer-container">
      <div className="footer-form-container form-div">
        {emptyField && (
          <div className={emptyField && "emptyFields"}>
            {lang == "EN"
              ? "Please fill the empty fields"
              : "برجاء ملئ الحقول الفارغة"}{" "}
          </div>
        )}
        <FooterForm
          emptyField={emptyFieldSetter}
          messageState={setMessageState}
        />
        {messageState && <div className="overlay"></div>}
        <Modal activeState={messageState} setActiveState={setMessageState} />
      </div>

      <div className="grid-container">
        <div className="footer-wrapper">
          <div className="logo">
            <img
              src={Logo}
              width="160px"
              className="footer-logo"
              height="70px"
              alt=""
            />
          </div>
          <div className="contact-methods">
            <div className="grid-contained">
              <table>
                <thead>
                  <tr>
                    <th className="arabic-font">
                      {lang === "EN" ? "Other Methods" : "طرق اخري للتواصل"}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <img src={Phone} width="30px" height="30px" alt="" />
                      <span>+995-555-20-555-6</span>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <img src={Marker} width="30px" height="30px" alt="" />
                      <span>47 irakli abashidze.Vake</span>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div className="grid-contained">
              <table>
                <thead>
                  <tr>
                    <th className="arabic-font">
                      {lang === "EN" ? "Social Media" : "مواقع التواصل"}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <a href="https://www.facebook.com/manageArcis/?viewas=100000686899395&show_switched_toast=0&show_invite_to_follow=0&show_switched_tooltip=0&show_podcast_settings=0&show_community_review_changes=0&show_community_rollback=0&show_follower_visibility_disclosure=0">
                        <i className="fa-brands fa-facebook"></i>
                        <span>Facebook</span>
                      </a>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <a href="https://instagram.com/arcis.development?igshid=ZGUzMzM3NWJiOQ==">
                        <i className="fa-brands fa-instagram"></i>
                        <span>Instagram</span>
                      </a>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <a href="https://twitter.com/ArcisGeorgia?t=rKvPPexVpOT1LlamXz_hmg&s=08">
                        <i className="fa-brands fa-twitter"></i>
                        <span>Twitter</span>
                      </a>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <a href="https://www.linkedin.com/company/arcis-development/">
                        <i className="fa-brands fa-linkedin"></i>
                        <span>LinkedIn</span>
                      </a>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <div className="policy">
        <h3>
          All Rights Reserved @Arics<span className="orange">Development</span>
        </h3>
      </div>
    </div>
  );
};

export default Footer;
