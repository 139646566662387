import React from "react";
import "./comments.css";
import { NavLink } from "react-router-dom";
const CommentorsData = (props) => {
  return (
    <div className="comment-container">
      <i class="fa-solid fa-quote-left"></i>
      <div className="wrapper">
        <div className="text-container-comment">
          <h1>{props.name}</h1>
          <span>{props.city}</span>
        </div>
        <div className="comment-container-para">
          <p className="comment">{props.comment}</p>
        </div>
      </div>
      <i class="fa-solid fa-quote-right"></i>
    </div>
  );
};

export default CommentorsData;
