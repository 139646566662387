import React from "react";

export const FilterReducer = (state, action) => {
  switch (action.type) {
    case "ALL":
      return action.value;

    case "DAY":
      return action.value;

    case "WEEK":
      return action.value;
    case "MONTH":
      return action.value;
    default:
      return state;
  }
};
