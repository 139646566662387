import React, { useContext } from "react";
import "./employ.css";

import EmployeesData from "./EmployeesData";
import { GlobalContext } from "../../../GlobalContext/GlobalProvider";
const EmployeesSection = () => {
  const { employeesObj, lookupsData, langState, loading } =
    useContext(GlobalContext);
  const lang = langState.lang;
  const branches = lookupsData.Branches;
  console.log(branches);
  return (
    <div
      className={
        branches.employees > 0 ? "employees-container" : "employees-none"
      }
    >
      <div
        className={
          lang === "EN"
            ? "employees-headline"
            : "employees-headline arabic-font"
        }
      >
        <h1>
          {lang === "EN"
            ? "Still Dont trust us.."
            : "ان كنت ماذلت لا تثق بابدعاتنا.."}
        </h1>
        <h2>{lang === "EN" ? "Meet our leaders" : "تعرف اكتر علي موظفينا"}</h2>
      </div>
      <div className="employees-grid">
        {loading &&
          branches.map(({ employees, cityName_ar, cityName_en }) => {
            return employees.map(({ id, name_en, name_ar, title, image }) => {
              return (
                <EmployeesData
                  key={id}
                  nameEn={name_en}
                  nameAr={name_ar}
                  title={title}
                  image={image}
                  cityNameEN={cityName_en}
                  cityNameAR={cityName_ar}
                  lang={lang}
                />
              );
            });
          })}
      </div>
    </div>
  );
};

export default EmployeesSection;
