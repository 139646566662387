import React, { useContext, useEffect, useState } from "react";
import "./body.css";
import { GlobalContext } from "../../../GlobalContext/GlobalProvider";
import Comments from "./bodyComp/Comments";
import axios from "axios";
let commentsFilterResults;
const CommentBody = () => {
  const { filterState, langState } = useContext(GlobalContext);
  const [comments, setComments] = useState([]);
  const [commentLoader, setCommentLoader] = useState([]);
  const [pageIncrementer, setIncrementer] = useState(1);
  const [metaData, setMeta] = useState({});
  const lang = langState.lang;
  function incrementHandler() {
    if (metaData.last_page != metaData.current_page) {
      setIncrementer(pageIncrementer + 1);
    }
  }
  function decrementHandler() {
    if (metaData.current_page > 1) {
      setIncrementer(pageIncrementer - 1);
    } else {
      setIncrementer(1);
    }
  }

  useEffect(() => {
    axios
      .get(`https://admin.arcis.ge/api/GeneralReview?page=${pageIncrementer}`)
      .then((response) => {
        setCommentLoader(true);
        setMeta(response.data.response.meta);
        return setComments(response.data.response.data);
      })
      .catch((err) => console.log(`Catched this error while fetching ${err}`));
    setCommentLoader(false);
  }, [pageIncrementer]);

  let commentContent;
  console.log(filterState);
  if (comments.length > 0) {
    const currentDate = new Date();
    let startDate;
    let filteredComments;
    if (filterState === "all") {
      console.log("first");
      commentContent = comments.length ? (
        comments.map(({ id, name, country, message, date }) => {
          return (
            <Comments
              key={id}
              personName={name}
              personCountry={country}
              date={date}
              personComment={message}
            />
          );
        })
      ) : (
        <h2 className="text-absolute">No comments to show</h2>
      );
    }
    if (filterState === "day") {
      startDate = new Date(currentDate.getTime()) - 24 * 60 * 60 * 1000;

      filteredComments = comments.filter((comment) => {
        const commentDate = new Date(comment.created_at);

        return commentDate >= startDate && commentDate <= currentDate;
      });
      commentContent = filteredComments.length ? (
        filteredComments.map(({ id, name, country, message, date }) => {
          return (
            <Comments
              key={id}
              personName={name}
              personCountry={country}
              date={date}
              personComment={message}
            />
          );
        })
      ) : (
        <h2 className="text-absolute">
          {"No comments to show try changing the filter"}
        </h2>
      );
    }
    if (filterState === "week") {
      startDate = new Date(currentDate.getTime()) - 7 * 24 * 60 * 60 * 1000;
      filteredComments = comments.filter((comment) => {
        const commentDate = new Date(comment.created_at);

        return commentDate >= startDate && commentDate <= currentDate;
      });
      commentContent = filteredComments.length ? (
        filteredComments.map(({ id, name, country, message, date }) => {
          return (
            <Comments
              key={id}
              personName={name}
              personCountry={country}
              date={date}
              personComment={message}
            />
          );
        })
      ) : (
        <h2 className="text-absolute">
          {"No comments to show try changing the filter"}
        </h2>
      );
    }
    if (filterState === "month") {
      startDate = new Date(currentDate.getTime()) - 30 * 24 * 60 * 60 * 1000;
      filteredComments = comments.filter((comment) => {
        const commentDate = new Date(comment.created_at);

        return commentDate >= startDate && commentDate <= currentDate;
      });
      commentContent = filteredComments.length ? (
        filteredComments.map(({ id, name, country, message, date }) => {
          return (
            <Comments
              key={id}
              personName={name}
              personCountry={country}
              date={date}
              personComment={message}
            />
          );
        })
      ) : (
        <h2 className="text-absolute">
          {"No comments to show try changing the filter"}
        </h2>
      );
    }
  } else {
    commentContent = <div className="text-absolute">No comments to show</div>;
  }

  return (
    <div className="comment-body-conainter">
      <div>
        <h2 className={lang === "AR" && "arabic-font"}>
          {lang === "EN" ? "Comments" : "التعليقات"}
        </h2>
      </div>
      <div className="grid-display-comments">{commentContent}</div>
      <div className="pagination">
        <div
          onClick={decrementHandler}
          className={
            metaData.current_page === 1
              ? "previous-page arrow-container disabled-arrow-rev"
              : "previous-page arrow-container active-arrow-container"
          }
        >
          <i class="fa-solid fa-arrow-left rev-arrow"></i>
          <span> Prev</span>
        </div>
        <div className="pagination-subData">
          <h4>
            {" "}
            page {metaData.current_page} of {metaData.last_page}
          </h4>
        </div>
        <div
          onClick={incrementHandler}
          className={
            metaData.last_page === metaData.current_page
              ? "next-page arrow-container disabled-arrow-rev "
              : "next-page arrow-container active-arrow-container "
          }
        >
          <span>Next</span>
          <i class="fa-solid fa-arrow-right rev-arrow"></i>
        </div>
      </div>
    </div>
  );
};

export default CommentBody;
