import React from "react";
import "./About.css";
import AboutHeader from "./AboutUsComponents/AboutHeader/AboutHeader";
import AboutHistoryComp from "./AboutUsComponents/AboutHistory/AboutHistoryComp";
import MainGoal from "./AboutUsComponents/MainGoal/MainGoal";
import Locations from "./AboutUsComponents/AboutLocations/Locations";
import EmployeesSection from "./AboutUsComponents/finalAboutSection/FinalAboutSection";
import Footer from "../Footer/Footer";
const AboutUs = () => {
  return (
    <div className="about-main-container">
      <AboutHeader />
      <AboutHistoryComp />
      <MainGoal />
      <Locations />
      <EmployeesSection />
      <Footer />
    </div>
  );
};

export default AboutUs;
