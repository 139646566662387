import React from "react";
import "./ProjectsData.css";
import ProjectsDataComp from "./ProjectsDataComp";
const ProjectsData = (props) => {
  return (
    <div className="data-page-container">
      <ProjectsDataComp />
    </div>
  );
};

export default ProjectsData;
