import React, { useState } from "react";
import "./whats.css";
const WhatsSide = () => {
  const [text, setText] = useState("");
  const [stateButton, setBtnState] = useState(false);
  const sendingText = `https://wa.me/+995555205556/?text=${text}`;
  console.log(text);
  function onValInput(e) {
    setText(e.target.value);
  }
  function changeState() {
    setBtnState(!stateButton);
  }

  return (
    <div className="whats-app-container">
      <div
        className={
          stateButton
            ? "whats-chat-container scale-up"
            : "whats-chat-container scale-down"
        }
      >
        <div>
          <h3>WhatsApp chat</h3>
        </div>
        <div className="chat-service">
          <span>Customer service</span>
        </div>
        <div className="text-chatIn">
          <input
            type="text"
            placeholder="Write a Message"
            onChange={onValInput}
          />
          <div className="link-container">
            <a href={sendingText}>
              <i class="fa-solid fa-paper-plane"></i>
            </a>
          </div>
        </div>
      </div>
      <div onClick={changeState} className="whatsBtn-container">
        <i class="fa-brands fa-whatsapp"></i>
      </div>
    </div>
  );
};

export default WhatsSide;
