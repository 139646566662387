import React, { useContext, useState } from "react";
import "./filter.css";

import { GlobalContext } from "../../../../GlobalContext/GlobalProvider";
const Filter = () => {
  const { stateDispatcher, langState } = useContext(GlobalContext);
  const [obj, setObj] = useState([{ id: 1 }, { id: 2 }, { id: 3 }, { id: 4 }]);
  const [className, classSetter] = useState(1);
  const lang = langState.lang;
  function labelActiveHandler(idx) {
    classSetter(obj[idx - 1].id);
  }
  console.log(className);
  function radioHandler(e) {
    stateDispatcher({
      type: e.target.value,
      value: e.target.value.toLowerCase(),
    });
  }

  return (
    <form className="filter-form">
      <label
        onClick={() => labelActiveHandler(1)}
        className={
          className === 1 ? "inputs-radio active-radio" : "inputs-radio"
        }
        htmlFor="all"
      >
        All
      </label>
      <input
        onChange={radioHandler}
        className="inputs-radio radios"
        type="radio"
        name="filter"
        value="ALL"
        id="all"
      />
      <label
        onClick={() => labelActiveHandler(2)}
        className={
          className === 2 ? "inputs-radio active-radio" : "inputs-radio"
        }
        htmlFor="day"
      >
        Today
      </label>
      <input
        onChange={radioHandler}
        className="inputs-radio radios"
        type="radio"
        name="filter"
        value="DAY"
        id="day"
      />
      <label
        onClick={() => labelActiveHandler(3)}
        className={
          className === 3 ? "inputs-radio active-radio" : "inputs-radio"
        }
        htmlFor="week"
      >
        This week
      </label>
      <input
        onChange={radioHandler}
        className="inputs-radio radios"
        type="radio"
        name="filter"
        value="WEEK"
        id="week"
      />
      <label
        onClick={() => labelActiveHandler(4)}
        className={
          className === 4 ? "inputs-radio active-radio" : "inputs-radio"
        }
        htmlFor="month"
      >
        This month
      </label>
      <input
        onChange={radioHandler}
        className="inputs-radio radios"
        type="radio"
        name="filter"
        value="MONTH"
        id="month"
      />
    </form>
  );
};

export default Filter;
