import React, { useState } from "react";
import "./locations.css";
import { Parallax } from "react-parallax";
import MainImage from "./images/Georgia-2109x1406.jpg";
import { useInView } from "react-intersection-observer";
import { useContext } from "react";
import { GlobalContext } from "../../../GlobalContext/GlobalProvider";
import Spinner from "../../../spinner/Spinner";
const Locations = () => {
  const [refrenceSm, inView] = useInView();
  const [refrenceIn, inviewReffed] = useInView();
  const { officeDataState, langState, lookupsData, loading } =
    useContext(GlobalContext);
  const lang = langState.lang;
  const inViewHandler = (index) => {
    return inView
      ? `word-sm-${index} words-small words-sm-down`
      : `word-sm-${index} words-small`;
  };
  const branches = lookupsData.Branches;
  const inViewReffedHandler = (idx) => {
    return inviewReffed ? `word-${idx} words words-up` : `word-${idx} words`;
  };
  return (
    <div className="locations-container">
      <div className="headline-animated" ref={refrenceIn}>
        <h1
          className={
            lang === "EN"
              ? "headline-location"
              : "headline-location arabic-font"
          }
        >
          <div className={inViewReffedHandler("one")}>
            {lang === "EN" ? "We're" : "نحن"}
          </div>
          <div className={inViewReffedHandler("two")}>
            {lang === "EN" ? "located" : "مقرنا"}
          </div>
          <div className={inViewReffedHandler("three")}>
            {lang === "EN" ? "in" : "في"}
          </div>
        </h1>
      </div>
      <div className="parallax-wrapper">
        <div className="overlay-locations"></div>
        <Parallax className="main-location" strength={200} bgImage={MainImage}>
          <div
            className={
              lang === "EN"
                ? "parallax-content"
                : "parallax-content arabic-font"
            }
          >
            <h3>{lang === "EN" ? "Georgia" : "جيورجيا"}</h3>
            <i class="fa-solid fa-location-dot bounce"></i>
          </div>
        </Parallax>
        <div className="responsive-bg-loc main-locations">
          <div className="parallax-content">
            <h3>{lang === "EN" ? "Georgia" : "جيورجيا"}</h3>
            <i class="fa-solid fa-location-dot bounce"></i>
          </div>
        </div>
      </div>
      <div
        ref={refrenceSm}
        className={lang === "EN" ? "text-office" : "text-office arabic-font"}
      >
        {lang === "EN" ? (
          <h3>
            <div className={inViewHandler("one")}>But</div>
            <div className={inViewHandler("two")}>we</div>
            <div className={inViewHandler("three")}>have</div>
            <div className={inViewHandler("four")}>offices</div>
            <div className={inViewHandler("five")}>in</div>
          </h3>
        ) : (
          <h3>
            <div className={inViewHandler("one")}>لكن</div>
            <div className={inViewHandler("two")}>لدينا</div>
            <div className={inViewHandler("three")}>مكاتب</div>
            <div className={inViewHandler("four")}>في هذة</div>
            <div className={inViewHandler("five")}>الدول</div>
          </h3>
        )}
      </div>
      <div className="countries">
        {loading &&
          branches.map(({ id, cityName_en, cityName_ar, location, image }) => {
            const mainBranch =
              cityName_en === "Georgia" ? (
                <div
                  className={
                    lang === "EN" ? "main-branch" : "main-branch arabic-font"
                  }
                >
                  {lang === "EN" ? "Main Office" : "الفرع الرئيسي"}
                </div>
              ) : (
                ""
              );
            return (
              <div id={id} className="country">
                <div className="country-overlay"></div>
                {mainBranch}
                <img src={image} alt="" />
                <div className="text-bounce">
                  <h3>{lang === "AR" ? cityName_ar : cityName_en}</h3>
                  <i class="fa-solid fa-location-dot bounce-2"></i>
                </div>

                <div className="location">
                  <p className="location-excat">{location}</p>
                </div>
              </div>
            );
          })}
      </div>
    </div>
  );
};

export default Locations;
