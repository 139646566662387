import React from "react";
import ProjectsPanel from "./images/projectsPanel.png";
import Projects1 from "./images/projects1.jpg";
import Projects2 from "./images/projects2.jpg";
import Projects3 from "./images/projects3.jpeg";
const PanelData = [
  {
    id: 1,
    image: ProjectsPanel,
    title_EN: "Projects",
    title_AR: "المشاريع",
    article_AR: "حان الوقت لرؤية العجائب",
    article_EN: "Time to see our wonders",
  },
  {
    id: 2,
    image: Projects1,
    title_EN: "Resort",
    title_AR: "منتجع",
    article_AR: "ماونتن جيت هو جوهرة نهر الانانوري",
    article_EN: "Mountain gate the diamond of ananuri lake",
  },
  {
    id: 3,
    image: Projects2,
    title_EN: "Resort",
    title_AR: "منتجع",
    article_AR: "الاناقة في صورتها المعمارية منتجع بازاليتي",
    article_EN: "Elegancy in short words",
  },
  {
    id: 4,
    image: Projects3,
    title_EN: "Business Towers",
    title_AR: "ابراج تجارية",
    article_AR: "اعجوبة التجارة في جيورجيا",
    article_EN: "Georgian business wonder",
  },
];

export default PanelData;
