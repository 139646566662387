import React, { useContext, useState, useEffect } from "react";
import { GlobalContext } from "../../../GlobalContext/GlobalProvider";
import { CSSTransition } from "react-transition-group";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination, Scrollbar, A11y } from "swiper";
import { useSwiper } from "swiper/react";
import { Anchor } from "antd";
import { HashLink } from "react-router-hash-link";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import "swiper/css";
import "swiper/swiper-bundle.css";
import { useInView } from "react-intersection-observer";
import "./projectsDataStylev2.css";
import "./ProjectsData.css";
import { useLocation } from "react-router-dom";
import axios from "axios";
import Spinner from "../../../spinner/Spinner";
import { NavLink } from "react-router-dom";
import ProjectsData from "./ProjectsData";
const ProjectsDataComp = () => {
  const { projDataLoad, projData, langState, projComments } =
    useContext(GlobalContext);

  const [sentMessage, setSentMessage] = useState(false);
  let managmentFeesPrec = Number(projData.proberty_manage) / 100;
  const { Link } = Anchor;
  const lang = langState.lang;
  console.log(projComments);
  const [btnState, setBtnState] = useState(false);

  const [containerState, setContainerActive] = useState(null);
  const [navState, setNavState] = useState(false);
  const [mainImage, setMainImage] = useState("");
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [message, setMessage] = useState("");
  const [project_id, setProjId] = useState(projData.id);
  const [incomeGross, setGross] = useState("");
  const [calculation, setCalculation] = useState(false);
  const [roiIdx, setRoiIdx] = useState(undefined);
  function incomeHandler(index) {
    let price = projData.ProjectElements[index].price;
    let calc = projData.ProjectElements[index].roi;
    // let calcPrec = calc / 100;
    let income = (price * calc) / 100;
    setGross(income);
    setCalculation(true);
    setRoiIdx(index);
  }
  let propertyManagment = incomeGross * managmentFeesPrec;

  function nameHandler(e) {
    setName(e.target.value);
  }
  function emailHandler(e) {
    setEmail(e.target.value);
  }

  function phoneHandler(e) {
    setPhone(e.target.value);
  }
  function messageHandler(e) {
    setMessage(e.target.value);
  }

  function contactSubmitHandler(e) {
    e.preventDefault();

    setSentMessage(true);
    axios
      .post("https://admin.arcis.ge/api/ProjectContact/store", {
        name,
        email,
        phone,
        message,
        project_id,
      })
      .then((response) => console.log(response))
      .catch((err) => console.log(err));
  }
  console.log(projData);
  useEffect(() => {
    setMainImage(projData.mainImage);
  }, [projData]);

  function navHandler() {
    setNavState(!navState);
  }

  function clickProjHandler() {
    setBtnState(!btnState);
  }
  function activeImage(imgIndex) {
    return containerState?.id === imgIndex
      ? "sub-picture active-picture"
      : "sub-picture";
  }
  function containerHandler(imgIndex) {
    return containerState?.id === imgIndex
      ? "sub-picture-container active-container"
      : "sub-picture-container";
  }

  function clickHandler(idx) {
    let subImage = projData.subImages[idx - 1].url;
    setContainerActive({ id: idx });
    setMainImage(subImage);
  }
  console.log(projComments);
  const content = !projDataLoad ? (
    <div className="overlay-loader">
      <div className="sub-wrapper-load">
        <i className="fa-solid fa-building building"></i>
        <h1>
          <span className="orange-a">A</span>rcis
        </h1>
      </div>
    </div>
  ) : (
    <div className="Data-container">
      <header className="data-header-proj">
        <div className="data-heading-proj">
          <h1
            className={
              lang === "EN" ? "responsive-h1" : "responsive-h1 arabic-font"
            }
          >
            {lang === "EN" ? projData.title_en : projData.title_ar}
          </h1>
          <div onClick={navHandler} className="responsive-btn-proj">
            <i
              className={
                navState
                  ? "fa-solid fa-bars bars bars-rotate"
                  : "fa-solid fa-bars bars"
              }
            ></i>
            <i
              className={
                navState
                  ? "fa-solid fa-angle-up arrow arrow-rotate"
                  : "fa-solid fa-angle-up arrow"
              }
            ></i>
          </div>
          <div
            className={
              navState ? "active-lnks-wrapper links-wrapper" : "links-wrapper"
            }
          >
            <div
              className={
                lang === "EN" ? "page-links" : "page-links arabic-font"
              }
            >
              {/* <NavLink to={"#dev-phase"}>development phase</NavLink> */}
              {/* <HashLink to={"#dev-phase"}>development phase</HashLink> */}
              <HashLink
                to="#invest"
                className={lang === "EN" ? "" : "arabic-font"}
              >
                {lang === "EN" ? "Investment" : "الاستثمار"}
              </HashLink>
              <HashLink
                to="#view"
                className={lang === "EN" ? "" : "arabic-font"}
              >
                {lang === "EN" ? "Project View" : "صور للمشروع"}
              </HashLink>
              <HashLink
                to="#dev-phase"
                className={lang === "EN" ? "" : "arabic-font"}
              >
                {lang === "EN" ? "Development phase" : "مراحل الانشاء"}
              </HashLink>
              <HashLink
                to="#contact"
                className={lang === "EN" ? "" : "arabic-font"}
              >
                {lang === "EN" ? "Contact us" : "تواصل معنا"}
              </HashLink>
            </div>
          </div>
        </div>
        <div className="video-container-proj">
          <video
            width="50%"
            height="50%"
            className="video-proj"
            loop
            muted
            autoPlay
          >
            {projData.video ? <source src={projData.video} /> : <Spinner />}
          </video>
        </div>
        <section className="sub-heading-proj">
          <h2 className="philos">Our philosophy</h2>
          <div className="sub-heading-info">
            <div className="first-text">
              <h4>Innovating Real Estate.</h4>
            </div>
            <div className="mid-text">
              <h4>
                Offering a wide variety of apartments and high qualty customer
                service.
              </h4>
            </div>
            <div className="last-text">
              <h4>Maintaing the status of the quality developer.</h4>
            </div>
          </div>
        </section>
      </header>
      <main>
        {/* ==================================================---- FIRST SECTION INSIDE THE PROJECTS--================================================== */}
        <section className="room-pictures-container">
          <div id="view" className="main-picture-container">
            {mainImage ? (
              <img
                className="main-picture"
                src={mainImage}
                width="100%"
                height="100%"
                alt=""
              />
            ) : (
              <Spinner />
            )}
          </div>
          <Swiper
            modules={{ Navigation, Pagination }}
            spaceBetween={0}
            speed={500}
            Pagination={{ clickable: true }}
            loop={false}
            slidesPerView={2.5}
          >
            <div className="sub-picture-wrapper">
              {projData.subImages.map(({ url }, idx) => {
                return (
                  <SwiperSlide>
                    <div
                      key={idx}
                      onClick={() => clickHandler(idx + 1)}
                      className={containerHandler(idx + 1)}
                    >
                      <div className="overlay picture-carousel-lay"></div>
                      {url ? (
                        <img
                          src={url}
                          className={activeImage(idx + 1)}
                          alt=""
                        />
                      ) : (
                        <Spinner />
                      )}
                    </div>
                  </SwiperSlide>
                );
              })}
            </div>
          </Swiper>
        </section>
        {/* ==================================================---- SECOND SECTION INSIDE THE PROJECTS--================================================== */}
        <section className="development-section">
          <h2
            className={
              lang === "EN"
                ? "development-header"
                : "development-header arabic-font"
            }
          >
            {lang === "EN" ? "Development phase" : "مراحل البناء"}
          </h2>
          <div id="dev-phase" className="development-grid ">
            {projData.DevelopmentPhases
              ? projData.DevelopmentPhases.map(
                  ({ id, text_ar, text_en, image }) => {
                    return (
                      <div key={id} className="development-wrapper">
                        <div className="development-container">
                          <div className="text-container">
                            <div
                              className={
                                lang === "EN"
                                  ? "text-wrapper-proj"
                                  : "text-wrapper-proj arabic-font"
                              }
                            >
                              <h3>{lang === "EN" ? text_en : text_ar}</h3>
                            </div>
                          </div>
                          <img src={image} alt="phase-development" />
                        </div>
                      </div>
                    );
                  }
                )
              : lang === "EN"
              ? "No Development Phase Available"
              : "لا يوجد مراحل للتطوير لهذا المشروغ"}
          </div>
        </section>

        {/* ----------------===================================================== INFRASTRUCTURE SECTION =======================-------------0000000000000000000000 */}
        <section className="structure-section">
          <div className="section-header-container">
            <h2 className={lang === "AR" && "arabic-font"}>
              {lang === "EN"
                ? "Infrastructure and details"
                : "البنية التحتية والتفاصيل"}
            </h2>
          </div>
          <div className="structure-container">
            {projData.Infrastructures.map(({ name_en, name_ar, id }, idx) => {
              return (
                <div key={idx} className="structure-headlines">
                  <h3 className={lang === "EN" ? "" : "arabic-font"}>
                    {lang === "EN" ? name_en : name_ar}
                  </h3>
                </div>
              );
            })}
          </div>
          <div className="spaces-area">
            <div className="picture-and-description">
              <div className="struc-image">
                <img src={projData.mainImage} alt="" />
              </div>
              <div className="struc-data">
                <p className={lang === "AR" && "arabic-font"}>
                  {lang === "EN" ? projData.details_en : projData.details_ar}
                </p>
              </div>
            </div>
            {/* =================================================== SUB DATA =====================================-------------- */}
            {lang === "EN" ? (
              <h4 className="unit-content">Each unit contains</h4>
            ) : (
              <h4 className="arabic-font unit-content">
                كل وحدة تحتوي علي الاتي
              </h4>
            )}
            <div className="sub-data-wrapper">
              <div className="sub-data">
                {projData.Units.map(
                  ({ id, name_en, name_ar, elements }, idx) => {
                    return (
                      <ul
                        key={id}
                        className={
                          lang === "EN"
                            ? "data-list"
                            : "data-list ar-list arabic-font"
                        }
                      >
                        <span>{lang === "EN" ? name_en : name_ar}</span>
                        {elements.map(({ name_en, name_ar, id }) => (
                          <li
                            key={id}
                            className={
                              lang === "EN"
                                ? "list-data-item"
                                : "list-data-item arabic-font"
                            }
                          >
                            {lang === "EN" ? name_en : name_ar}
                          </li>
                        ))}
                      </ul>
                    );
                  }
                )}
              </div>
            </div>
          </div>
          <div id="invest" className="income-container">
            <div className="investing-heading">
              {projData.sold ? (
                <div className="investment-message">
                  <h2>Investment Not Available</h2>
                  <span>Sold Out</span>
                </div>
              ) : (
                <h2 className={lang === "AR" && "arabic-font"}>
                  {lang === "EN"
                    ? `Why invest in ${projData.title_en}`
                    : `لماذا تستثمر في ${projData.title_ar}؟ `}
                </h2>
              )}
            </div>

            {/* -------------------------------------------------------========================== roi section ==================------------- */}
            <div className={projData.sold ? "message-none" : "roi-section"}>
              {Boolean(projData.ProjectElements[0]) ? (
                projData.ProjectElements.map(
                  ({ id, name_ar, name_en, roi, space }, idx) => {
                    return (
                      <div
                        className={
                          roiIdx === idx
                            ? "roi-container roi-active"
                            : "roi-container"
                        }
                      >
                        <h2 className={lang === "AR" && "arabic-font"}>
                          {lang === "EN" ? name_en : name_ar}
                        </h2>
                        <div className="sub-details-container">
                          <div className="sub-details">
                            <span
                              className={
                                lang === "EN"
                                  ? "sub-headline"
                                  : "sub-headline arabic-font"
                              }
                            >
                              {lang === "EN" ? "space" : "المساحة"}
                            </span>
                            <span className="space"> {space}sqm </span>
                          </div>
                          <div className="sub-details">
                            <span
                              className={
                                lang === "EN"
                                  ? "sub-headline"
                                  : "sub-headline arabic-font"
                              }
                            >
                              {lang === "EN" ? "roi" : "نسبة العائد"}
                            </span>
                            <span className="return-precentage"> {roi}%</span>
                          </div>
                        </div>
                        <button
                          onClick={() => incomeHandler(idx)}
                          className="investing-btn"
                        >
                          investing Income
                        </button>
                      </div>
                    );
                  }
                )
              ) : (
                <h2 className="no-units">
                  {lang === "EN"
                    ? "No Units available for investment"
                    : "لا يوجد وحدات متاحة للاستثمار"}
                </h2>
              )}
            </div>
            {calculation ? (
              <div className="income-sub-container ">
                <div>
                  <p className={lang === "AR" && "arabic-font"}>
                    {lang === "EN" ? "Income Gross" : "الدخل الإجمالي"}
                  </p>
                  <span>{Math.round(incomeGross) + "$"}</span>
                </div>
                <span className="operators">-</span>
                <div>
                  <p className={lang === "AR" && "arabic-font"}>
                    {lang === "EN"
                      ? `Property Manage ${projData.proberty_manage}%`
                      : `%${projData.proberty_manage}اعمال صيانة`}
                  </p>
                  <span>{Math.round(propertyManagment) + "$"}</span>
                </div>
                <span className="operators">=</span>
                <div>
                  <p className={lang === "AR" && "arabic-font"}>
                    {lang === "EN" ? "Your Income" : "دخلك السنوي"}
                  </p>
                  <span>
                    {Math.abs(
                      Math.round(projData.income_gross - propertyManagment)
                    ) + "$"}
                  </span>
                </div>
              </div>
            ) : projData.sold ? (
              <h className="sold-state">No Units Available</h>
            ) : (
              <h2
                className={
                  Boolean(projData.ProjectElements[0])
                    ? "empty-message"
                    : "message-none"
                }
              >
                Please Choose the unit above
              </h2>
            )}
          </div>
          <div className="contact-us-proj">
            <h2 className={lang === "AR" && "arabic-font"}>
              {lang === "EN"
                ? "Eager to know more about this project contact us via email"
                : "متحمسون لمعرفة المزيد عن هذا المشروع، يُرجى التواصل معنا عبر البريد الإلكتروني."}
            </h2>
            <button className="contact-btn" onClick={clickProjHandler}>
              Contact us
            </button>
          </div>
          <div
            className={
              btnState
                ? "contact-form-proj proj-form-wrapper form-active-proj"
                : "contact-form-proj proj-form-wrapper"
            }
          >
            <form
              id="contact"
              action=""
              onSubmit={contactSubmitHandler}
              className="proj-form"
            >
              <div className="input-container-proj">
                <input
                  type="text"
                  id="name"
                  name="full-name"
                  onChange={nameHandler}
                  required
                  className="form-input-proj"
                />
                <label className="proj-label" htmlFor="name">
                  Full name
                </label>
                <span className="proj-form-info">Ex:John carter leo</span>
              </div>
              <div className="input-container-proj">
                <input
                  required
                  onChange={phoneHandler}
                  type="number"
                  id="phone"
                  className="form-input-proj"
                />
                <label className="proj-label" htmlFor="phone">
                  Phone number
                </label>
                <span className="proj-form-info">Ex:+2388899405523</span>
              </div>
              <div className="input-container-proj">
                <input
                  required
                  type="text"
                  id="email"
                  onChange={emailHandler}
                  className="form-input-proj"
                />
                <label className="proj-label" htmlFor="email">
                  Email
                </label>
                <span className="proj-form-info">Ex:support@example.com</span>
              </div>
              <div className="input-container-proj">
                <input
                  required
                  type="text"
                  id="message"
                  onChange={messageHandler}
                  className="form-input-proj"
                />
                <label className="proj-label" htmlFor="message">
                  Message
                </label>
                <span className="proj-form-info">Ex:support@example.com</span>
              </div>

              <div className="last-form-el">
                <input
                  className="proj"
                  type="text"
                  placeholder={projData.title_en}
                  value={""}
                  disabled
                />
                {projData.sold ? (
                  <button type="submit" disabled className="submit-proj">
                    Submit
                  </button>
                ) : (
                  <button type="submit" className="submit-proj">
                    Submit
                  </button>
                )}
              </div>
              <div
                className={sentMessage ? "sent-message" : "sent-message-none"}
              >
                <h2>
                  {lang === "EN"
                    ? "Usually we reply within 48 hours please be patient"
                    : "عادة نرد في غضون 48 ساعة"}
                </h2>
              </div>
            </form>
          </div>
        </section>
        <section className="comment-section">
          <h2
            className={
              lang === "EN"
                ? "comments-headline"
                : "comments-headline arabic-font"
            }
          >
            {projComments.length < 1
              ? lang === "EN"
                ? "No Comments Yet Available for this project Add yours from outside"
                : "لا يوجد تعليقات بعد لهذا المشروع اضف تعليقك الخاص بالخارج"
              : lang === "EN"
              ? "The Comment section of the project"
              : "التعليقات الخاصة بالمشروع"}
          </h2>
          <div className="projects-comment-sect">
            {projDataLoad &&
              projComments.map(({ message, country, name, date }) => {
                return (
                  <div className="comment__wrapper-proj">
                    <div className="name-country">
                      <h2 className="person-name">{name}</h2>
                      <h3 className="person-country">{country}</h3>
                    </div>
                    <div className="comment__body-proj">
                      <i className="fa-solid fa-quote-left quote-top"></i>
                      <p>{message}</p>
                      <i className="fa-solid fa-quote-right quote-bot"></i>
                    </div>

                    <div className="date-comment">{date}</div>
                  </div>
                );
              })}
          </div>
        </section>
      </main>
      <footer className="proj-footer">
        <h4>All rights reserved @ArcisDevelopment</h4>
      </footer>
    </div>
  );

  return (
    /* ==================================================----  HEADER SECTION INSIDE THE PROJECTS--================================================== */
    content
  );
};

export default ProjectsDataComp;
