import React from "react";
import Navbar from "../NavBar/Navbar";
import FirstSection from "./FirstSect/FirstSection";
import VideoSect from "./VideoSection/VideoSect";
import ProjectPanel from "./ProjectPanel/ProjectPanel";
import DataSect from "./DataSect/DataSect";
import Glimpse from "./ProjectGlimpse/Glimpse";
import CommentsSection from "./CommentSection/CommentsSection";
import Community from "./community/community";
import Footer from "../Footer/Footer";
const LandingPage = () => {
  return (
    <div className="main-container__index">
      <FirstSection />
      <VideoSect />
      <ProjectPanel />
      <DataSect />

      <Community />
      <CommentsSection />
      <Footer />
    </div>
  );
};

export default LandingPage;
