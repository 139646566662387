import React, { useContext } from "react";
import { useInView } from "react-intersection-observer";
import "./sect.css";
import Data from "./Data";
import DataComp from "./DataComp";
import { GlobalContext } from "../../GlobalContext/GlobalProvider";
const DataSect = () => {
  const { langState } = useContext(GlobalContext);
  const lang = langState.lang;

  const { ref, inView } = useInView({
    threshold: 0,
  });

  return (
    <div className="info-container">
      <div className={lang === "EN" ? "" : "arabic-font"}>
        <h1
          ref={ref}
          className={inView ? "data-header appear" : "data-header "}
        >
          {lang === "EN"
            ? "  We own the market and your happiness..."
            : "نمتلك السوق وسعادتك..."}
        </h1>
      </div>
      <div className="aligning-data">
        {Data.map((value, id) => {
          return (
            <DataComp
              ref={ref}
              key={value.id}
              picture={value.picture}
              button={value.button}
              textAr={value.textAr}
              buttonAr={value.buttonAr}
              text={value.text}
              link={value.link}
            />
          );
        })}
      </div>
    </div>
  );
};

export default DataSect;
