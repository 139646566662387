import React from "react";
import "./projects.css";
import ProjHeader from "./projComponents/projectsHeader/ProjHeader";
import Filter from "./projComponents/projectsHeader/FilterComponent/Filter";
import Projects from "./projComponents/ProjectsFile/projects";
import Footer from "../Footer/Footer";
const projects = () => {
  return (
    <>
      <div className="projects-main-container">
        <ProjHeader />
        <Filter />
        <Projects />
      </div>
      <Footer />
    </>
  );
};

export default projects;
