import React, { useContext, useEffect, useState } from "react";
import "./filter.css";
import "./projectfilter.css";
import { GlobalContext } from "../../../../GlobalContext/GlobalProvider";
import axios from "axios";
import Projects from "../../ProjectsFile/projects";
const Filter = () => {
  const { lookupsData, loading, langState, mainProjDispatcher, projects } =
    useContext(GlobalContext);

  const lang = langState.lang;
  const [LocationData, setLocationData] = useState("");
  const [propertyData, setPropertyData] = useState("");
  const [processData, setProcessData] = useState("");
  const [type_id, setId] = useState("");
  const [searchResult, setResult] = useState([]);
  const [searchText, setText] = useState("");
  const [fetcherLoad, setFetcher] = useState(false);
  const [filteredLocations, setLocation] = useState([]);
  const [filteredTitles, setTitles] = useState([]);
  const [filteredProjs, setProjs] = useState(null);

  function projTitleFilter(value) {
    const filterProjLocations = projects.filter((proj) => {
      return (
        proj &&
        proj.location &&
        proj.location.toLowerCase().includes(value.toLowerCase())
      );
    });
    const filteredTitles = projects.filter((proj) => {
      return (
        proj &&
        proj.title_en &&
        proj.title_ar &&
        proj.title_en.toLowerCase().includes(value.toLowerCase())
      );
    });
    setTitles(filteredTitles);
    setLocation(filterProjLocations);
  }
  function textHandler(e) {
    setText(e.target.textContent);
    setLocation([]);
    setTitles([]);
  }

  function idHandler(e) {
    setId(e.target.value);
  }

  function locationHandler(e) {
    setText(e.target.value);
    setLocationData(e.target.value);
    projTitleFilter(e.target.value);
  }
  function processHandler(e) {
    setProcessData(e.target.value);
  }
  function submitHandler(e) {
    e.preventDefault();

    axios
      .get(
        `https://admin.arcis.ge/api/search?searchText=${searchText}&type_id=${type_id}&type_of_processing=${processData}`
      )
      .then((res) => {
        if (res.data.data.length) {
          setFetcher(true);
          setProjs(res.data.data);
          mainProjDispatcher({
            type: "STORE",
            filteredProj: res.data.data,
            activated: fetcherLoad,
          });
        } else {
          setProjs([]);
          mainProjDispatcher({
            type: "STORE",
            filteredProj: [],
            activated: fetcherLoad,
          });
        }
      })
      .catch((err) => console.log(err));
    axios
      .get(
        `https://admin.arcis.ge/api/search?type_id=${type_id}&location=${LocationData}&type_of_processing=${processData}`
      )
      .then((res) => {
        if (res.data.data.length) {
          setFetcher(true);
          setProjs(res.data.data);
          mainProjDispatcher({
            type: "STORE",
            filteredProj: res.data.data,
          });
        } else {
          setProjs([]);
          mainProjDispatcher({
            type: "STORE",
            filteredProj: [],
            activated: fetcherLoad,
          });
        }
      })
      .catch((err) => console.log(err));

    setFetcher(false);
  }

  return (
    <form
      id="proj-form-container"
      onSubmit={submitHandler}
      className="projects-form form-container"
      action={`https://admin.arcis.ge/api/search?location=${LocationData}&type_id=${type_id}&type_of_proccessing=${processData}&searchText=${searchText}`}
      method="GET"
    >
      <input
        onChange={locationHandler}
        type="search"
        placeholder="What is your location..?"
        name="search"
        value={searchText}
        className="search-bar"
        id="Search-bar"
      />
      <div className="results-wrapper">
        <ul className="search-results">
          {filteredTitles.length && searchText
            ? filteredTitles.map(({ title_en, title_ar }, idx) => {
                return (
                  <li onClick={textHandler} key={idx}>
                    {lang === "EN" ? title_en : title_ar}
                  </li>
                );
              })
            : ""}
          {filteredLocations.length && searchText
            ? filteredLocations.map(({ location }, idx) => {
                return (
                  <li onClick={textHandler} key={idx}>
                    {location}
                  </li>
                );
              })
            : ""}
        </ul>
      </div>
      <div className="select projects-select">
        <select
          onChange={idHandler}
          name="city"
          className="property-select property-select-projects"
          id="city"
        >
          <option selected disabled>
            Type Of Property
          </option>
          <option value="">All</option>
          {loading &&
            lookupsData.ProjectTypes.map(({ id, name_en, name_ar }) => {
              return (
                <option key={id} value={id}>
                  {lang === "EN" ? name_en : name_ar}
                </option>
              );
            })}
        </select>
        <select onChange={processHandler} name="city" className="processing">
          <option selected disabled>
            Type of Processing
          </option>
          <option value="">All</option>
          <option value="rent">Property for Rent</option>
          <option value="purchase">Property for Purchase</option>
        </select>
      </div>
      <div className="search-btn-results">
        <button
          type="submit"
          className="btn-style projects-btn-search"
          id="search-btn"
        >
          Search
        </button>
        <div
          className={
            filteredProjs?.length < 1 ? "visible search-mssg" : "search-mssg"
          }
        >
          {lang === "EN" ? "No search results" : "لا يوجد نتائج بحث"}
        </div>
      </div>
    </form>
  );
};

export default Filter;
