import React from "react";
import EventsParent from "./BlogEvents/EventsParent";
import BlogHeader from "./BlogHeader/BlogHeader";
import Footer from "../Footer/Footer";
const Blog = () => {
  return (
    <div className="blog-wrapper">
      <BlogHeader />
      <EventsParent />
      <Footer />
    </div>
  );
};

export default Blog;
