import React, { useContext } from "react";
import "./revs.css";
import { NavLink } from "react-router-dom";
import { GlobalContext } from "../GlobalContext/GlobalProvider";
const RevsNavbar = () => {
  const { projDataLoad, langState } = useContext(GlobalContext);
  return (
    <div className="revs-navbar">
      <nav>
        <ul className={langState.lang === "AR" && "arabic-font"}>
          <li className={langState.lang === "AR" && "arabic-font"}>
            <NavLink
              className={langState.lang === "AR" && "arabic-font"}
              to={"/"}
            >
              {langState.lang === "EN" ? "Home" : "ألرئيسية"}
            </NavLink>
          </li>
          <li className={langState.lang === "AR" && "arabic-font"}>
            <NavLink
              className={langState.lang === "AR" && "arabic-font"}
              to={"/Projects"}
            >
              {langState.lang === "EN" ? "Projects" : "المشاريع"}
            </NavLink>
          </li>
        </ul>
      </nav>
    </div>
  );
};

export default RevsNavbar;
